import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { FieldArray, useFormikContext } from "formik";
import { MdClose } from "react-icons/md";
import Restrictions from "./Restrictions";
import FormInput from "src/components/Inputs/Formik/FormInput";
import styles from "./styles.module.scss";

const AttributeNames = ({
  fieldName,
  attribute,
  touched,
  errors,
  arrayHelpers,
}) => {
  const { t } = useTranslation();

  const removeName = async (index) => {
    arrayHelpers.remove(index);
  };

  return (
    <div className={styles.container}>
      <div className={styles.attributeNamesLabel}>
        {t("createProofTemplate.attribute.names")}
      </div>
      {attribute.names.map(({ id }, index) => (
        <div key={index}>
          <div className={styles.header}>
            <FormInput
              key={`${index}`}
              name={`${fieldName}.names.${index}`}
              className={styles.inputContainer}
              inputClassName={styles.input}
            />
            {index < attribute.names.length - 1 && (
              <div className={styles.close} onClick={() => removeName(index)}>
                <MdClose size={22} />
              </div>
            )}
          </div>
        </div>
      ))}
      {touched && errors && (
        <div className={styles.error}>
          {Array.isArray(errors) ? "One of the items has errors" : errors}
        </div>
      )}
    </div>
  );
};

const Attribute = ({ name, onRemove }) => {
  const { t } = useTranslation();
  const { getFieldProps } = useFormikContext();
  const { value: attribute } = getFieldProps(name);

  var names = attribute.names || [];
  if (names.length === 0 || names[names.length - 1] !== "") {
    names.push("");
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <FormInput
          label={t("createProofTemplate.attribute.id")}
          name={`${name}.attributeId`}
          className={styles.inputContainer}
          inputClassName={styles.input}
        />
        {onRemove && (
          <div className={styles.closeAttribute} onClick={onRemove}>
            <MdClose size={22} />
          </div>
        )}
      </div>

      <FieldArray name={`${name}.names`}>
        {({ ...arrayHelpers }) => (
          <AttributeNames
            fieldName={name}
            attribute={attribute}
            arrayHelpers={arrayHelpers}
          />
        )}
      </FieldArray>

      <FieldArray name={`${name}.restrictions`}>
        {({ form, ...fieldArrayHelpers }) => {
          const addAttribute = async () => {
            fieldArrayHelpers.push({
              id: uuid(),
              cred_def_id: "",
              schema_id: "",
              schema_name: "",
              schema_version: "",
              schema_issuer_did: "",
              attribute_value: "",
              issuer_did: "",
            });
          };

          const removeAttribute = async (index) => {
            fieldArrayHelpers.remove(index);
          };

          return (
            <Restrictions
              name={`${name}.restrictions`}
              items={attribute.restrictions}
              onAdd={addAttribute}
              onRemove={removeAttribute}
            />
          );
        }}
      </FieldArray>
    </div>
  );
};

export default Attribute;
