import React from "react";
import { Link } from "react-router-dom";
// import ReactImageFallback from "react-image-fallback";
import ImageFallback from "src/components/ReactImageFallback";
import Skeleton from "react-loading-skeleton";

import styles from "./styles.module.scss";

const OrganizationCard = ({
  loading,
  organization: { id, name, imageUri, network } = {},
}) => {
  return (
    <Link className={styles.link} to={`/organizations/${id}`}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          {loading ? (
            <Skeleton className={styles.image} width={80} height={80} />
          ) : (
            // <ReactImageFallback
            //   fallbackImage={<div className={styles.fallback} />}
            //   className={styles.image}
            //   src={imageUri}
            //   alt="logo"
            // />
            <ImageFallback
              fallbackImage={<div className={styles.fallback} />}
              className={styles.image}
              src={imageUri}
              alt="logo"
            />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.name}>{loading ? <Skeleton /> : name}</div>
          <div className={styles.network}>
            {loading ? <Skeleton /> : `[${network}]`}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default OrganizationCard;
