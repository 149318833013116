import axios from "axios";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";

const responseTransform = (response) => ({
  status: "success",
  code: response.status,
  data: response.data,
});

const errorTransform = (error) => ({
  status: "error",
  code: error?.response?.status,
  error: error?.response?.data || "An error has occurred",

  // throw new Error({
  //   status: "error",
  //   code: error?.response?.status,
  //   error: error?.response?.data || "An error has occurred",
  // });
});

const factory = ({
  baseURL = "",
  responseTransform: propsResponseTransform,
  errorTransform: propsErrorTransform,
}) => {
  // Does not cache requests, if you want to cache use { cache: true } in the requestParams
  const cacheAdapter = cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
  });
  const debounceAdapter = throttleAdapterEnhancer(cacheAdapter, {
    threshold: 250,
  });

  const client = axios.create({
    baseURL,
    adapter: debounceAdapter,
  });

  client.interceptors.response.use(
    propsResponseTransform ?? responseTransform,
    propsErrorTransform ?? errorTransform
  );

  return ({ headers = {}, cancelToken, authToken, ...requestParams }) =>
    client({
      ...requestParams,
      headers: {
        ...headers,
        ...(authToken
          ? {
              Authorization: `Bearer ${authToken}`,
            }
          : {}),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      cancelToken,
    });
};

export default factory;
