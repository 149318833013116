import React, { useState } from "react";
import { Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import { MdClose } from "react-icons/md";
import Button from "src/components/Inputs/Button";

import styles from "./styles.module.scss";
import {
  useCreateCredentialDefinition,
  useCreateSchema,
} from "src/services/hooks/definitions";

const AttributeNames = ({ schema, touched, errors, arrayHelpers }) => {
  const { t } = useTranslation();

  const removeName = async (index) => {
    arrayHelpers.remove(index);
  };

  // create the default or next entry
  var attributeNames = schema.attributeNames || [];
  if (
    attributeNames.length === 0 ||
    attributeNames[attributeNames.length - 1] !== ""
  ) {
    attributeNames.push("");
  }

  return (
    <div className={styles.container}>
      <div className={styles.attributeTitle}>
        {t("createCredentialDefinition.schema.attributeNames")}
      </div>
      {attributeNames.map((value, index) => (
        <div className={styles.header} key={index}>
          <FormInput
            key={`${index}`}
            name={`schema.attributeNames.${index}`}
            inputClassName={styles.inputAttribute}
          />
          {index < schema.attributeNames.length - 1 && (
            <div className={styles.close} onClick={() => removeName(index)}>
              <MdClose size={22} />
            </div>
          )}
        </div>
      ))}
      {touched && errors && (
        <div className={styles.error}>
          {Array.isArray(errors) ? "One of the items has errors" : errors}
        </div>
      )}
    </div>
  );
};

const CreateSchemaAndDefinitionForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  // const [credential, _] = useState({
  const [credential] = useState({
    tag: "default",
    enableRevocation: false,
    schema: {
      name: "",
      version: "",
      attributeNames: [""],
    },
  });

  const { mutateAsync: createSchema } = useCreateSchema();
  const { mutateAsync: createCredentialDefinition } =
    useCreateCredentialDefinition();

  const submitForm = async ({ schema, ...rest }) => {
    const { id: schemaId } = await createSchema(schema);
    const data = await createCredentialDefinition({
      schemaId,
      ...rest,
    });
    onSubmit(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={credential}
          validationSchema={Yup.object().shape({
            schema: Yup.object().shape({
              name: Yup.string().required(),
              version: Yup.string().required(),
              attributeNames: Yup.array().required(),
            }),
          })}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput
                name="schema.name"
                label={t("createCredentialDefinition.schema.name")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FormInput
                name="schema.version"
                label={t("createCredentialDefinition.schema.version")}
                disabled={isSubmitting}
                className={styles.input}
              />

              <FieldArray name={"schema.attributeNames"}>
                {({
                  form: {
                    values: { schema },
                  },
                  ...arrayHelpers
                }) => (
                  <AttributeNames
                    schema={schema}
                    disabled={isSubmitting}
                    arrayHelpers={arrayHelpers}
                    className={styles.input}
                  />
                )}
              </FieldArray>
              <FormInput
                name="tag"
                label={t("createCredentialDefinition.tag")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FormInput
                type="checkbox"
                name="enableRevocation"
                label={t("createCredentialDefinition.enableRevocation")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <Button disabled={isSubmitting} className={styles.button}>
                {isSubmitting
                  ? t("createCredentialDefinition.submitModalLoading")
                  : t("createCredentialDefinition.submitModal")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateSchemaAndDefinitionForm;
