// import deprecatedOrganizationRequest from "src/services/request/organizationRequest";
import organizationRequest from "src/services/request/qOrganizationRequest";

export const getProofRecordsFactory =
  ({ accessToken }) =>
  ({ templateId }) =>
    organizationRequest({
      authToken: accessToken,
      method: "get",
      url: `v1/proof`,
      params: {
        templateId,
      },
    });

export const createProofRequestFromTemplate =
  ({ accessToken }) =>
  ({ templateId, ...data }) =>
    organizationRequest({
      authToken: accessToken,
      method: "post",
      url: `v1/proof/template/${templateId}`,
      data,
    });
