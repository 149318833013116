import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  getCredentialDefinitionsFactory,
  getCredentialDefinitionByIdFactory,
  getSchemasFactory,
  createSchemaFactory,
  createCredentialDefinitionFactory,
  getInteractionFlowsFactory,
  createInteractionFlowFactory,
  updateInteractionFlowFactory,
  deleteInteractionFlowFactory,
} from "src/api/definitions";
import { useOrganizationFromContext } from "src/services/hooks/state";

export const CREDENTIAL_DEFINITIONS_QUERY = "credential-definitions";
export const SCHEMAS_QUERY = "schemas";
export const INTERACTION_FLOWS_QUERY = "interaction-flows";

export const useSchemas = () => {
  const organization = useOrganizationFromContext();

  return useQuery(
    `organizations/${organization?.id}/${SCHEMAS_QUERY}`,
    getSchemasFactory({ accessToken: organization?.accessToken })
  );
};

export const useHydratedCredentialDefinitions = () => {
  const organization = useOrganizationFromContext();
  const { data: schemas } = useSchemas();

  const { data, ...q } = useQuery(
    `organizations/${organization?.id}/${CREDENTIAL_DEFINITIONS_QUERY}`,
    getCredentialDefinitionsFactory({
      accessToken: organization?.accessToken,
    })
  );

  const schemasById = (schemas || []).reduce(
    (res, schema) => ({ ...res, [schema.id]: schema }),
    {}
  );

  return {
    ...q,
    data: data
      ? data.map((c) => ({
          ...c,
          schema: schemasById[c.schemaId],
        }))
      : [],
  };
};

export const useCredentialDefinitionById = (id) => {
  const organization = useOrganizationFromContext();

  return useQuery(
    `organizations/${organization?.id}/${CREDENTIAL_DEFINITIONS_QUERY}/${id}`,
    () =>
      getCredentialDefinitionByIdFactory({
        accessToken: organization?.accessToken,
      })(id),
    { enabled: !!id }
  );
};

export const useCreateSchema = () => {
  const queryClient = useQueryClient();
  const organization = useOrganizationFromContext();
  return useMutation(
    createSchemaFactory({
      accessToken: organization?.accessToken,
    }),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(
          `organizations/${organization?.id}/${SCHEMAS_QUERY}`
        );
      },
    }
  );
};

export const useCreateCredentialDefinition = () => {
  const queryClient = useQueryClient();
  const organization = useOrganizationFromContext();
  return useMutation(
    createCredentialDefinitionFactory({
      accessToken: organization?.accessToken,
    }),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(
          `organizations/${organization?.id}/${CREDENTIAL_DEFINITIONS_QUERY}`
        );
      },
    }
  );
};

export const useInteractionFlows = () => {
  const organization = useOrganizationFromContext();

  return useQuery(
    `organizations/${organization?.id}/${INTERACTION_FLOWS_QUERY}`,
    getInteractionFlowsFactory({ accessToken: organization?.accessToken })
  );
};

const interactionFlowMutationFactory = (callFactory) => () => {
  const queryClient = useQueryClient();
  const organization = useOrganizationFromContext();
  return useMutation(
    callFactory({
      accessToken: organization?.accessToken,
    }),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(
          `organizations/${organization?.id}/${INTERACTION_FLOWS_QUERY}`
        );
      },
    }
  );
};

export const useCreateInteractionFlow = interactionFlowMutationFactory(
  createInteractionFlowFactory
);
export const useDeleteInteractionFlow = interactionFlowMutationFactory(
  deleteInteractionFlowFactory
);
export const useUpdateInteractionFlow = interactionFlowMutationFactory(
  updateInteractionFlowFactory
);
