import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import Button from "src/components/Inputs/Button";

import styles from "./styles.module.scss";
import FormSelect from "src/components/Inputs/Formik/FormSelect";
import {
  useCreateCredentialDefinition,
  useSchemas,
} from "src/services/hooks/definitions";

const CreateSchemaAndDefinitionForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const { mutateAsync: createCredentialDefinition } =
    useCreateCredentialDefinition();

  const { data: schemas } = useSchemas();

  const submitForm = async (cred) => {
    onSubmit(await createCredentialDefinition(cred));
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={{
            tag: "default",
            enableRevocation: false,
            // schemaId: "",
          }}
          validationSchema={Yup.object().shape({
            tag: Yup.string().required(),
            schemaId: Yup.string().required(),
          })}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormSelect
                name="schemaId"
                label={t("createCredentialDefinition.schemaId")}
                disabled={isSubmitting}
                className={styles.input}
                options={schemas.map(({ id, name: label }) => ({ id, label }))}
              />
              <FormInput
                name="tag"
                label={t("createCredentialDefinition.tag")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FormInput
                type="checkbox"
                name="enableRevocation"
                label={t("createCredentialDefinition.enableRevocation")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <Button disabled={isSubmitting} className={styles.button}>
                {isSubmitting
                  ? t("createCredentialDefinition.submitModalLoading")
                  : t("createCredentialDefinition.submitModal")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateSchemaAndDefinitionForm;
