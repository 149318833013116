import React, { useContext, useEffect, useState } from "react";
import ProofTemplatesTable from "./ProofTemplatesTable";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { ModalContext } from "src/providers/ModalProvider";
import Button from "src/components/Inputs/Button";
import { useProofTemplates } from "src/services/hooks/verifications";
import { ProofTemplatesContext } from "src/providers/ProofTemplatesProvider";
import ConfirmModal from "src/components/ConfirmModal";
import { MdRefresh } from "react-icons/md";

const VerificationsPage = ({ history, location }) => {
  const { t } = useTranslation();

  const [confirmRemoveIsOpen, setConfirmRemoveIsOpen] = useState(false);
  const {
    data: proofTemplates,
    isLoading: loading,
    error,
    refetch: loadProofTemplates,
  } = useProofTemplates();
  const context = useContext(ProofTemplatesContext);
  const {
    deleteProofTemplate: { execute: deleteProofTemplate },
  } = context;

  const { toggleModal } = useContext(ModalContext);
  const [deletedProofTemplate, setDeletedProofTemplate] = useState();

  useEffect(() => {
    loadProofTemplates();
  }, [loadProofTemplates]);

  const onCreateProofTemplate = async () => {
    toggleModal("proof-template", {
      onSubmit: () => {
        loadProofTemplates();
      },
    });
  };
  const onCreateProofRequest = async ({ id: templateId }) => {
    toggleModal("create-proof-request", { templateId });
  };
  const onEditProofTemplate = async (proofTemplate) => {
    // todo: see if we can avoid this translation
    // formik seems to have issues with handling "id" as a mapped attribute
    proofTemplate.attributes.forEach((a) => (a.attributeId = a.id));
    toggleModal("proof-template", {
      proofTemplate,
      onSubmit: () => {
        loadProofTemplates();
      },
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{t("verifications.title")}</h4>
        <Button onClick={onCreateProofTemplate}>
          {t("verifications.add").toUpperCase()}
        </Button>
        <Button
          className={styles.refresh}
          variant="outlined"
          onClick={() => loadProofTemplates()}
        >
          <MdRefresh size={22} />
        </Button>
      </div>
      {error && <div>{error?.message || error.toString()}</div>}
      {!error && (
        <div className={styles.table}>
          <ProofTemplatesTable
            loading={loading}
            items={proofTemplates}
            onCreateProofRequest={onCreateProofRequest}
            onEdit={onEditProofTemplate}
            onItemClicked={({ id }) =>
              history.push(`${location.pathname}/${id}`)
            }
            onDelete={(w) => {
              setDeletedProofTemplate(w);
              setConfirmRemoveIsOpen(true);
            }}
          />
        </div>
      )}

      {confirmRemoveIsOpen && (
        <ConfirmModal
          isOpen={confirmRemoveIsOpen}
          setIsOpen={setConfirmRemoveIsOpen}
          submitProps={{ color: "warning" }}
          submitText="Delete"
          submitLoadingText="Deleting..."
          message="Are you sure you want to delete this verification"
          onSubmit={async () => {
            await deleteProofTemplate(deletedProofTemplate.id);
            await loadProofTemplates();
          }}
        />
      )}
    </div>
  );
};

export default VerificationsPage;
