import React from "react";
import styles from "./styles.module.scss";
import Button from "src/components/Inputs/Button";
import Restriction from "./Restriction";
import { useTranslation } from "react-i18next";

const Restrictions = ({ name, items, onAdd, onRemove, errors, touched }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {items.map(({ id }, index) => (
        <Restriction
          key={index}
          name={`${name}.${index}`}
          onRemove={() => onRemove(index)}
        />
      ))}
      {touched && errors && (
        <div className={styles.error}>
          {Array.isArray(errors) ? "One of the items has errors" : errors}
        </div>
      )}

      <Button type="button" onClick={onAdd}>
        {t("createProofTemplate.attribute.addRestriction")}
      </Button>
    </div>
  );
};

export default Restrictions;
