import React from "react";
import Table, {
  TableRow,
  TableRowCell,
  Tbody,
  Thead,
  TheadCell,
  TheadRow,
} from "src/components/Table";
import { MdDelete, MdEdit } from "react-icons/md";

import { useTable } from "react-table";

import styles from "./styles.module.scss";

const WebhooksTable = ({ onEdit, onDelete, loading, items }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Url",
        accessor: "url",
        className: styles.url,
      },
      {
        Header: "Event Type",
        accessor: "eventType",
      },
      {
        Header: "Status Filter",
        accessor: "statusFilter",
      },
      {
        Header: "Tags",
        Cell: ({ row }) => (
          <div>
            {row.original?.tagFilter[row.original?.tagFilter.length - 1] === ""
              ? row.original?.tagFilter.pop()
              : ""}
            {row.original?.tagFilter.map((tag) => (
              <div key={tag} className={styles.miniContainer}>
                {tag}
              </div>
            ))}
          </div>
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <MdEdit
              className={styles.iconButton}
              onClick={() => onEdit && onEdit(row.original)}
            />
            <MdDelete
              className={styles.iconButton}
              onClick={() => onDelete && onDelete(row.original)}
            />
          </div>
        ),
      },
    ],
    [onEdit, onDelete]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: items || [] });

  return (
    <Table
      loading={loading}
      isEmpty={!items?.length}
      className={styles.table}
      {...getTableProps()}
    >
      <Thead>
        {headerGroups.map((headerGroup) => (
          <TheadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TheadCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </TheadCell>
            ))}
          </TheadRow>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableRowCell
                    {...cell.getCellProps()}
                    className={cell.column?.className}
                  >
                    {cell.render("Cell")}
                  </TableRowCell>
                );
              })}
            </TableRow>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default WebhooksTable;
