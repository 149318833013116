import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getProofTemplatesFactory,
  createProofTemplateFactory,
  deleteProofTemplateFactory,
  updateProofTemplateFactory,
  getProofTemplateByIdFactory,
} from "src/api/proofTemplates";
import {
  createProofRequestFromTemplate,
  getProofRecordsFactory,
} from "src/api/proofs";
import { useOrganizationFromContext } from "src/services/hooks/state";

const getQueryKey = (organizationId, argsKeys) => {
  let keys = [];
  if (argsKeys) {
    keys = Array.isArray(argsKeys) ? argsKeys : [argsKeys];
  }
  return [`organizations/${organizationId}/proof-templates`, ...keys];
};

const connectionHookFactory = (callFactory) => () => {
  const queryClient = useQueryClient();
  const organization = useOrganizationFromContext();
  return useMutation(callFactory({ accessToken: organization?.accessToken }), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(getQueryKey(organization.id));
    },
  });
};

export const useProofRecordsByProofTemplateId = (id) => {
  const organization = useOrganizationFromContext();

  return useQuery(`organizations/${organization?.id}/proof/${id}`, () =>
    getProofRecordsFactory({
      accessToken: organization?.accessToken,
    })({
      templateId: id,
    })
  );
};

export const useProofTemplates = () => {
  const organization = useOrganizationFromContext();
  return useQuery(
    getQueryKey(organization.id),
    getProofTemplatesFactory({ accessToken: organization?.accessToken })
  );
};

export const useCreateProofTemplate = connectionHookFactory(
  createProofTemplateFactory
);
export const useCreateProofRequestFromTemplate = connectionHookFactory(
  createProofRequestFromTemplate
);

export const useDeleteProofTemplate = connectionHookFactory(
  deleteProofTemplateFactory
);

export const useUpdateProofTemplate = connectionHookFactory(
  updateProofTemplateFactory
);

export const useProofTemplateById = (id) => {
  const organization = useOrganizationFromContext();

  return useQuery(
    getQueryKey(organization.id, id),
    () =>
      getProofTemplateByIdFactory({ accessToken: organization?.accessToken })(
        id
      ),
    { enabled: !!id }
  );
};
