import React, { useContext } from "react";
import Modal from "src/components/Modal";
import ProofTemplateForm from "./ProofTemplateForm";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { ProofTemplatesContext } from "src/providers/ProofTemplatesProvider";

const CreateProofTemplateModal = ({
  proofTemplate,
  isOpen,
  setIsOpen,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const context = useContext(ProofTemplatesContext);
  const {
    createProofTemplate: { execute: createProofTemplate },
    updateProofTemplate: { execute: updateProofTemplate },
  } = context;
  const close = () => setIsOpen(false);

  const submitModal = async (w) => {
    let response;
    if (proofTemplate) {
      response = await updateProofTemplate(w);
    } else {
      response = await createProofTemplate(w);
    }
    // if (status === "success") {
    onSubmit(response);
    close();
    // }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>
            {proofTemplate
              ? t("createProofTemplate.updateProofTemplate")
              : t("createProofTemplate.createProofTemplate")}
          </h4>
          <ProofTemplateForm
            proofTemplate={proofTemplate}
            onSubmit={submitModal}
          />
        </div>
      )}
    </Modal>
  );
};

export default CreateProofTemplateModal;
