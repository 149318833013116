import React from "react";
import Modal from "src/components/Modal";

import InteractionFlowForm from "../InteractionFlowForm";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import {
  useCreateInteractionFlow,
  useUpdateInteractionFlow,
} from "src/services/hooks/definitions";

const InteractionFlowFormModal = ({
  interactionFlow,
  isOpen,
  setIsOpen,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { mutateAsync: createInteractionFlow } = useCreateInteractionFlow();
  const { mutateAsync: updateInteractionFlow } = useUpdateInteractionFlow();

  const close = () => setIsOpen(false);

  const submitForm = async (w) => {
    let data;
    if (interactionFlow) {
      data = await updateInteractionFlow(w);
    } else {
      data = await createInteractionFlow(w);
    }
    onSubmit(data);
    close();
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>
            {interactionFlow
              ? t("interactionFlowForm.updateTitle")
              : t("interactionFlowForm.createTitle")}
          </h4>
          <InteractionFlowForm
            interactionFlow={interactionFlow}
            onSubmit={submitForm}
          />
        </div>
      )}
    </Modal>
  );
};

export default InteractionFlowFormModal;
