import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";

import logo from "src/assets/logo.svg";

import styles from "./styles.module.scss";
import { AuthContext } from "src/providers/AuthProvider";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "./Breadcrumbs";
import DropdownButton from "src/components/Dropdown/DropdownButton";
import { MenuItem } from "src/components/Dropdown/Menu";

const Header = ({ history }) => {
  const { t } = useTranslation();
  const { isSignedIn, user, signOut } = useContext(AuthContext);
  // const logout = async () => {
  //   await firebaseAuth.signOut();
  //   history.push("/");
  //   resetAccount();
  // };

  const logout = async () => {
    await signOut();
    history.push("/");
  };
  return (
    <div className={styles.container}>
      <Link to="/" className={styles.logo}>
        <img src={logo} alt="logo" height={30} />
      </Link>
      <Breadcrumbs />
      <div className={styles.spacer} />
      {!isSignedIn && (
        <>
          <Link to="/login" className={styles.navLink}>
            {t("header.login")}
          </Link>
          <Link to="/register" className={styles.navLink}>
            {t("header.register")}
          </Link>
        </>
      )}
      {isSignedIn && (
        <DropdownButton alignEnd title={user?.email}>
          <MenuItem key="header-account-settings">
            <Link to="/account-settings" className={styles.dropdownLink}>
              {t("header.accountSettings")}
            </Link>
          </MenuItem>
          <MenuItem key="header-logout" onClick={logout}>
            {t("header.logout")}
          </MenuItem>
        </DropdownButton>
      )}
    </div>
  );
};

export default withRouter(Header);
