import React from "react";
import copy from "copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";

import styles from "./styles.module.scss";
import { toast } from "react-toastify";

const DisplayWithCopy = ({ value }) => (
  <div className={styles.container}>
    <div className={styles.value}>{value}</div>
    <MdContentCopy
      size={20}
      className={styles.copy}
      onClick={(e) => {
        e.stopPropagation();
        copy(value);
        toast("Copied...");
      }}
    />
  </div>
);

export default DisplayWithCopy;
