import React from "react";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import Button from "src/components/Inputs/Button";
import Attributes from "./Attributes";

import styles from "./styles.module.scss";

const ProofTemplateForm = ({ proofTemplate, onSubmit }) => {
  const { t } = useTranslation();

  const submitForm = async (values) => {
    const template = {
      ...values,
      attributes: values.attributes.map(
        ({ names: rawNames, attributeId: id, ...a }) => {
          const names = Array.isArray(rawNames)
            ? rawNames
            : rawNames.split(",");
          if (names.length > 1) {
            a.names = names;
          } else {
            a.name = names[0];
          }
          return { ...a, id };
        }
      ),
    };
    onSubmit(template);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={
            proofTemplate || {
              name: "",
              version: "",
              attributes: [],
              predicates: [],
            }
          }
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            version: Yup.string().required(),
            attributes: Yup.array().of(
              Yup.object().shape({
                attributeId: Yup.string().required("missing id"),
                names: Yup.string().required("You must enter a value"),
              })
            ),
          })}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput
                name="name"
                label={t("createProofTemplate.name")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FormInput
                name="version"
                label={t("createProofTemplate.version")}
                disabled={isSubmitting}
                className={styles.input}
              />

              <FieldArray name="attributes">
                {({ form, ...fieldArrayHelpers }) => {
                  const addAttribute = async () => {
                    fieldArrayHelpers.push({
                      attributeId: "",
                      restrictions: [],
                      names: [],
                    });
                  };

                  const removeAttribute = async (index) => {
                    fieldArrayHelpers.remove(index);
                  };

                  return (
                    <Attributes
                      name={"attributes"}
                      items={form.values.attributes}
                      errors={form.errors.attributes}
                      touched={form.touched.attributes}
                      onAdd={addAttribute}
                      onRemove={removeAttribute}
                    />
                  );
                }}
              </FieldArray>
              <Button
                disabled={isSubmitting}
                className={styles.button}
                type="submit"
              >
                {isSubmitting
                  ? t("createProofTemplate.saving")
                  : t("createProofTemplate.save")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProofTemplateForm;
