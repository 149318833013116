import React, { useState, useContext } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import Button from "src/components/Inputs/Button";
import { AuthContext } from "src/providers/AuthProvider";

import styles from "./styles.module.scss";
import { Link, withRouter } from "react-router-dom";

const PasswordLogin = ({ history }) => {
  const [error, setError] = useState();
  const { t } = useTranslation();
  // const { isSignedIn, signIn } = useContext(AuthContext);
  const { signIn } = useContext(AuthContext);
  const handleLogin = async ({ email, password }) => {
    try {
      setError();
      await signIn({ email, password });
      setImmediate(() => history.push("/"));
    } catch (e) {
      setError(
        e._dartException.error.detail || e._dartException.error.toString()
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(),
            password: Yup.string().required(),
          })}
          onSubmit={handleLogin}
        >
          {({ isSubmitting }) => (
            <Form>
              <h6>{t("login.title")}</h6>
              <FormInput
                name="email"
                label={t("login.email")}
                className={styles.input}
                autoComplete="email"
              />
              <FormInput
                name="password"
                label={t("login.password")}
                type="password"
                className={styles.input}
                autoComplete="password"
              />
              {error && <div className={styles.error}>{error}</div>}
              <Button
                type="submit"
                disabled={isSubmitting}
                className={styles.button}
              >
                {t("login.login")}
              </Button>
              <div className={styles.footer}>
                <div>{t("login.noAccount")}</div>
                <Link to="/register">{t("login.register")}</Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default withRouter(PasswordLogin);
