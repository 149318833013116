import React, { useState } from "react";
import copy from "copy-to-clipboard";

import Button from "src/components/Inputs/Button";
import { useTranslation } from "react-i18next";
import { MdRemove, MdContentCopy } from "react-icons/md";

import styles from "./styles.module.scss";
import InfoLabel from "src/components/Layout/InfoLabel";
import ConfirmModal from "src/components/ConfirmModal";
import InfoItem from "src/containers/OrganizationPage/InfoItem";
// import ReactImageFallback from "react-image-fallback";
import ImageFallback from "src/components/ReactImageFallback";
import Input from "src/components/Inputs/Input";
import {
  useDeleteOrganization,
  useOrganizationById,
} from "src/services/hooks/organizations";

const OrganizationPage = ({
  history,
  match: {
    params: { organizationId },
  },
}) => {
  const [confirmRemoveIsOpen, setConfirmRemoveIsOpen] = useState(false);
  const { t } = useTranslation();
  const { isLoading: loading, data: organization } =
    useOrganizationById(organizationId);

  const { mutate: deleteOrganization } = useDeleteOrganization();
  if (loading) {
    return <div />;
  }
  const removeOrganization = async () => {
    await deleteOrganization(organization.id);
    history.push("/");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{organization.name}</h4>
        <InfoLabel>{t("organization.remove")}</InfoLabel>
        <Button
          color="warning"
          variant="icon-button"
          onClick={() => setConfirmRemoveIsOpen(true)}
        >
          <MdRemove size={18} />
        </Button>
      </div>
      <div className={styles.card}>
        <div className={styles.cardHeader}>{t("organization.accessToken")}</div>
        <div className={styles.accessToken}>
          <div className={styles.imageContainer}>
            {/* <ReactImageFallback
            fallbackImage={<div className={styles.fallback} />}
            className={styles.image}
            src={organization.imageUri}
            alt="logo"
          /> */}
            <ImageFallback
              fallbackImage={<div className={styles.fallback} />}
              className={styles.image}
              src={organization.imageUri}
              alt="logo"
            />
          </div>
          <div className={styles.accessTokenBody}>
            <Input value={organization.accessToken} disabled />
            <MdContentCopy
              size={20}
              className={styles.copy}
              onClick={() => copy(organization.accessToken)}
            />
          </div>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.cardHeader}>{t("organization.details")}</div>
        <div className={styles.organizationDetailsBody}>
          <InfoItem label="Network">{organization.network}</InfoItem>
          <InfoItem label="Agent Service Endpoint">
            {organization.agentEndpoint?.uri}
          </InfoItem>
          <InfoItem label="Issuer DID">{organization.issuerDid}</InfoItem>
          <InfoItem label="Issuer Balance">
            {organization.issuerPaymentAddressBalance}
          </InfoItem>
          <div className={styles.organizationDetailsBodySingleRow}>
            <InfoItem label="Issuer Verkey">
              {organization.issuerVerkey}
            </InfoItem>
            <InfoItem label="Issuer Payment Address">
              {organization.issuerPaymentAddress}
            </InfoItem>
          </div>
        </div>
      </div>
      {confirmRemoveIsOpen && (
        <ConfirmModal
          isOpen={confirmRemoveIsOpen}
          setIsOpen={setConfirmRemoveIsOpen}
          submitProps={{ color: "warning" }}
          submitText="Delete"
          submitLoadingText="Deleting..."
          message="Are you sure you want to delete this organization"
          onSubmit={removeOrganization}
        />
      )}
    </div>
  );
};

export default OrganizationPage;
