import React from "react";
import classNames from "classnames";

import TableRow from "./TableRow";
import Thead from "./Thead";
import Tbody from "./Tbody";
import TheadRow from "./TheadRow";
import TheadCell from "./TheadCell";
import TableRowCell from "./TableRowCell";

import styles from "./styles.module.scss";

const Table = ({
  className,
  loading,
  isEmpty,
  emptyState: EmptyState,
  children,
  ...props
}) => (
  <div {...props} className={classNames(className, styles.container)}>
    {children}
    {loading && <div className={styles.loading}>Loading...</div>}
    {!loading && isEmpty ? (
      <>
        {EmptyState ? (
          <EmptyState />
        ) : (
          <div className={styles.emptyState}>No data</div>
        )}
      </>
    ) : null}
  </div>
);

export { TableRow, Thead, Tbody, TheadRow, TheadCell, TableRowCell };

export default Table;
