import React, { useContext } from "react";
import ProofRecordsTable from "./ProofRecordsTable";
import { useTranslation } from "react-i18next";
import DetailsSectionHeader from "src/components/Layout/DetailsSectionHeader";
import DetailsRowDisplay from "src/components/Layout/DetailsRowDisplay";
import CollapsibleLayout from "src/components/Layout/CollapsibleLayout";
import { useProofTemplateById } from "src/services/hooks/verifications";
import Button from "src/components/Inputs/Button";
import { MdRefresh } from "react-icons/md";
import { useProofRecordsByProofTemplateId } from "src/services/hooks/verifications";
import styles from "./styles.module.scss";
import { ModalContext } from "src/providers/ModalProvider";

const VerificationPage = ({
  match: {
    params: { proofTemplateId },
  },
}) => {
  const { t } = useTranslation();
  const {
    data: proofRecords,
    isLoading: proofRecordsLoading,
    refetch: reloadProofRecords,
  } = useProofRecordsByProofTemplateId(proofTemplateId);

  const { data: proofTemplate, isLoading: loading } =
    useProofTemplateById(proofTemplateId);

  const { toggleModal } = useContext(ModalContext);
  const toggleCreateProofRequestModal = () => {
    toggleModal("create-proof-request", { proofTemplateId });
  };

  if (loading || !proofTemplate) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      <h4>{proofTemplate.name}</h4>
      <CollapsibleLayout>
        <div>
          <DetailsSectionHeader title={t("proofTemplate.details")} />
          <DetailsRowDisplay
            label={t("proofTemplate.version")}
            value={proofTemplate.version}
          />

          <DetailsSectionHeader title={t("proofTemplate.attributes")} />

          {proofTemplate?.attributes?.map(({ id, names }) => (
            <DetailsRowDisplay key={id} label={id} value={names} />
          ))}
        </div>
        <div>
          <DetailsSectionHeader title={t("proofTemplate.records")}>
            <div className={styles.actions}>
              <Button
                className={styles.actionButton}
                onClick={toggleCreateProofRequestModal}
              >
                {t("connection.request").toUpperCase()}
              </Button>
              <Button
                className={styles.refresh}
                variant="outlined"
                onClick={() => reloadProofRecords()}
              >
                <MdRefresh size={22} />
              </Button>
            </div>
          </DetailsSectionHeader>
          <ProofRecordsTable
            items={proofRecords}
            loading={proofRecordsLoading}
          />
        </div>
      </CollapsibleLayout>
    </div>
  );
};

export default VerificationPage;
