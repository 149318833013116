import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "src/providers/AuthProvider";
import { AccountContext } from "src/providers/AccountProvider";
import Welcome from "./Welcome";
import CreateOrganizationModal from "./CreateOrganizationModal";
import OrganizationsList from "./OrganizationsList";

import styles from "./styles.module.scss";
import { useOrganizations } from "src/services/hooks/organizations";

const DashboardPage = ({ history }) => {
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const { user } = useContext(AuthContext);

  const {
    data: organizations = [],
    isLoading: loadingOrgs,
    status: statusOrgs,
    refetch: loadOrganizations,
  } = useOrganizations();

  const {
    networks: {
      loading: loadingNetworks,
      data: networks,
      status: statusNetworks,
      execute: loadNetworks,
    },
  } = useContext(AccountContext);
  useEffect(() => {
    if (networks?.length) {
      return;
    }
    loadNetworks();
  }, [loadNetworks, networks?.length]);

  const error = statusOrgs === "error" && statusNetworks === "error";
  const loading = loadingOrgs || loadingNetworks;
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Welcome name={user?.displayName} />
        {!error ? (
          <OrganizationsList
            loading={loading}
            organizations={organizations}
            onAddOrganization={() => setCreateModalIsOpen(true)}
          />
        ) : (
          <div>An error has occurred</div>
        )}
      </div>
      <CreateOrganizationModal
        networks={networks}
        isOpen={createModalIsOpen}
        setIsOpen={setCreateModalIsOpen}
        onSubmit={({ id }) => {
          loadOrganizations();
          history.push(`/organizations/${id}`);
        }}
      />
    </div>
  );
};

export default DashboardPage;
