import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Label = ({ className, children, ...props }) => (
  <div className={classNames(styles.container, className)} {...props}>
    {children}
  </div>
);

export default Label;
