import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const TableRow = ({ className, children, ...props }) => (
  <div
    {...props}
    className={classNames(className, styles.container, {
      [styles.clickable]: props.onClick || props.clickable,
    })}
  >
    <div className={styles.row}>{children}</div>
  </div>
);

export default TableRow;
