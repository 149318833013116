import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const TableRowCell = ({ className, children, ...props }) => (
  <div {...props} className={classNames(className, styles.container)}>
    <div>{children}</div>
  </div>
);

export default TableRowCell;
