import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getConnectionsFactory,
  createConnectionFactory,
  deleteConnectionByIdFactory,
  getConnectionByIdFactory,
  getConnectionRecordLogsByConnectionIdFactory,
} from "src/api/connections";
import { getMessageFactory, createMessageFactory } from "src/api/messages";
import { useOrganizationFromContext } from "src/services/hooks/state";

const getQueryKey = (organizationId, argsKeys) => {
  let keys = [];
  if (argsKeys) {
    keys = Array.isArray(argsKeys) ? argsKeys : [argsKeys];
  }
  return [`organizations/${organizationId}/connections`, ...keys];
};

const connectionHookFactory = (callFactory) => () => {
  const queryClient = useQueryClient();
  const organization = useOrganizationFromContext();
  return useMutation(callFactory({ accessToken: organization?.accessToken }), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(getQueryKey(organization.id));
    },
  });
};

export const useConnections = (filters) => {
  const organization = useOrganizationFromContext();
  return useQuery(getQueryKey(organization.id, [filters]), (data) =>
    getConnectionsFactory({ accessToken: organization?.accessToken })(
      data.queryKey[1]
    )
  );
};

export const useCreateConnection = connectionHookFactory(
  createConnectionFactory
);
export const useDeleteConnections = () => {
  const queryClient = useQueryClient();
  const organization = useOrganizationFromContext();
  let mutationFn = deleteConnectionByIdFactory({
    accessToken: organization?.accessToken,
  });
  return useMutation((ids) => Promise.all(ids.map((id) => mutationFn(id))), {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(getQueryKey(organization.id));
    },
  });
};

export const useConnectionById = (id) => {
  const organization = useOrganizationFromContext();

  return useQuery(
    getQueryKey(organization.id, id),
    () =>
      getConnectionByIdFactory({ accessToken: organization?.accessToken })(id),
    { enabled: !!id }
  );
};

export const useConnectionRecordLogsById = (id) => {
  const organization = useOrganizationFromContext();

  return useQuery(
    getQueryKey(organization.id, [id, "record-log"]),
    () =>
      getConnectionRecordLogsByConnectionIdFactory({
        accessToken: organization?.accessToken,
      })(id),
    { enabled: false }
  );
};

export const useMessages = (filters) => {
  const organization = useOrganizationFromContext();
  return useQuery(getQueryKey(organization.id, [filters]), (data) =>
    getMessageFactory({ accessToken: organization?.accessToken })(
      data.queryKey[1]
    )
  );
};

export const useCreateMessage = connectionHookFactory(createMessageFactory);
