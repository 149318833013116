import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Button = ({ className, color, variant, children, ...props }) => (
  <button
    className={classNames(
      styles.button,
      styles[color],
      styles[variant],
      className
    )}
    {...props}
  >
    {children}
  </button>
);

export default Button;
