import React, { useContext, useState } from "react";
import ConnectionsTable from "./ConnectionsTable";
import { MdRefresh } from "react-icons/md";

import Button from "src/components/Inputs/Button";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { ModalContext } from "src/providers/ModalProvider";
import {
  useConnections,
  useDeleteConnections,
} from "src/services/hooks/connections";

const ConnectionsPage = ({ history, location }) => {
  const [filters, setFilters] = useState({});
  const [selected, setSelected] = useState({});
  const { t } = useTranslation();

  const {
    data: connections,
    error,
    isLoading: loading,
    refetch: loadConnections,
  } = useConnections(filters);

  const { mutate: deleteConnections, isLoading: deleteLoading } =
    useDeleteConnections();

  const { toggleModal } = useContext(ModalContext);

  const addConnection = async () => {
    toggleModal("invite-connection");
  };

  const hasSelectedConnection = Object.keys(selected).some(
    (key) => selected[key]
  );

  const onDeleteConnections = async () => {
    if (deleteLoading) {
      return;
    }
    // todo: maybe create a bulk delete endpoint or display a nice loading spinner on the
    // row when adding redux
    await deleteConnections(Object.keys(selected).filter((id) => selected[id]));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{t("connections.title")}</h4>
        {hasSelectedConnection ? (
          <Button
            color="warning"
            disabled={deleteLoading}
            onClick={onDeleteConnections}
          >
            {t("connections.remove").toUpperCase()}
          </Button>
        ) : (
          <Button onClick={addConnection}>
            {t("connections.add").toUpperCase()}
          </Button>
        )}
        <Button
          className={styles.refresh}
          variant="outlined"
          onClick={() => loadConnections()}
        >
          <MdRefresh size={22} />
        </Button>
      </div>
      {error && <div>{error?.message || error.toString()}</div>}
      {!error && (
        <>
          <div className={styles.table}>
            <ConnectionsTable
              loading={loading}
              items={connections}
              onItemClicked={({ id }) =>
                history.push(`${location.pathname}/${id}`)
              }
              filters={filters}
              setFilters={setFilters}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ConnectionsPage;
