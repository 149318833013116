import React, { useContext, useEffect, useState } from "react";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { OAuthScopesContext } from "src/providers/OAuthScopesProvider";
import OAuthScopesTable from "./OAuthScopesTable";
import OAuthScopeModal from "./OAuthScopeModal";
import Button from "src/components/Inputs/Button";
import ConfirmModal from "src/components/ConfirmModal";

const OAuthScopesPage = ({ history, location }) => {
  const [confirmRemoveIsOpen, setConfirmRemoveIsOpen] = useState(false);
  const [editedScope, setEditedScope] = useState();
  const [deletedScope, setDeletedScope] = useState();
  const [formModalOpen, setFormModalOpen] = useState(false);
  const { t } = useTranslation();
  const {
    scopes: { data: scopes, loading, error, execute: loadOAuthScopes },
    deleteScope: { execute: deleteScope },
  } = useContext(OAuthScopesContext);

  useEffect(() => {
    loadOAuthScopes();
  }, [loadOAuthScopes]);

  const toggleModal = (w) => {
    setEditedScope(w);
    setFormModalOpen(true);
    loadOAuthScopes();
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{t("oAuthScopes.title")}</h4>
        <Button onClick={() => toggleModal()}>{t("oAuthScopes.add")}</Button>
      </div>
      {error && <div>{error?.message || error.toString()}</div>}
      {!error && (
        <div className={styles.table}>
          <OAuthScopesTable
            loading={loading}
            items={scopes}
            onEdit={toggleModal}
            onDelete={(w) => {
              setDeletedScope(w);
              setConfirmRemoveIsOpen(true);
            }}
          />
        </div>
      )}
      {formModalOpen && (
        <OAuthScopeModal
          isOpen={formModalOpen}
          setIsOpen={setFormModalOpen}
          onSubmit={async () => {
            await loadOAuthScopes();
          }}
          scope={editedScope}
        />
      )}

      {confirmRemoveIsOpen && (
        <ConfirmModal
          isOpen={confirmRemoveIsOpen}
          setIsOpen={setConfirmRemoveIsOpen}
          submitProps={{ color: "warning" }}
          submitText="Delete"
          submitLoadingText="Deleting..."
          message="Are you sure you want to delete this scope"
          onSubmit={async () => {
            await deleteScope(deletedScope.id);
            await loadOAuthScopes();
          }}
        />
      )}
    </div>
  );
};

export default OAuthScopesPage;
