import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getOrganizations,
  deleteOrganizationById,
  createOrganization,
  getOrganizationById,
} from "src/api/organizations";

const ORGANIZATIONS_QUERY = "organizations";

export const useOrganizations = () =>
  useQuery(ORGANIZATIONS_QUERY, getOrganizations);

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteOrganizationById, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(ORGANIZATIONS_QUERY);
    },
  });
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(createOrganization, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(ORGANIZATIONS_QUERY);
    },
  });
};

export const useOrganizationById = (id) =>
  useQuery(`${ORGANIZATIONS_QUERY}/${id}`, () => getOrganizationById(id));
