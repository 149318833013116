import React, { useContext, useEffect, useState } from "react";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { WebhooksContext } from "src/providers/WebhooksProvider";
import WebhooksTable from "./WebhooksTable";
import WebhookFormModal from "./WebhookFormModal";
import Button from "src/components/Inputs/Button";
import ConfirmModal from "src/components/ConfirmModal";
import { MdRefresh } from "react-icons/md";

const WebhooksPage = () => {
  const [confirmRemoveIsOpen, setConfirmRemoveIsOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [editedWebhook, setEditedWebhook] = useState();
  const [deletedWebhook, setDeletedWebhook] = useState();
  const { t } = useTranslation();
  const {
    webhooks: { data: webhooks, loading, execute: loadWebhooks },
    deleteWebhook: { execute: deleteWebhook },
  } = useContext(WebhooksContext);

  useEffect(() => {
    loadWebhooks();
  }, [loadWebhooks]);
  const toggleModal = (w) => {
    setEditedWebhook(w);
    setFormOpen(true);
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{t("webhooks.title")}</h4>
        <Button onClick={() => toggleModal()}>
          {t("webhooks.add").toUpperCase()}
        </Button>
        <Button
          className={styles.refresh}
          variant="outlined"
          onClick={() => loadWebhooks()}
        >
          <MdRefresh size={22} />
        </Button>
      </div>
      <div className={styles.table}>
        <WebhooksTable
          loading={loading}
          items={webhooks}
          onEdit={toggleModal}
          onDelete={(w) => {
            setDeletedWebhook(w);
            setConfirmRemoveIsOpen(true);
          }}
        />
      </div>
      {formOpen && (
        <WebhookFormModal
          onSubmit={loadWebhooks}
          isOpen={formOpen}
          setIsOpen={setFormOpen}
          webhook={editedWebhook}
        />
      )}

      {confirmRemoveIsOpen && (
        <ConfirmModal
          isOpen={confirmRemoveIsOpen}
          setIsOpen={setConfirmRemoveIsOpen}
          submitProps={{ color: "warning" }}
          submitText="Delete"
          submitLoadingText="Deleting..."
          message="Are you sure you want to delete this webhook"
          onSubmit={async () => {
            await deleteWebhook(deletedWebhook.id);
            await loadWebhooks();
          }}
        />
      )}
    </div>
  );
};

export default WebhooksPage;
