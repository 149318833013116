import React, { useContext, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import Button from "src/components/Inputs/Button";
import { AuthContext } from "src/providers/AuthProvider";

import styles from "./styles.module.scss";
import { withRouter } from "react-router-dom";

const ConfirmEmailPage = ({ history, location }) => {
  const [error, setError] = useState();
  const { t } = useTranslation();
  // const { isSignedIn, confirmEmail } = useContext(AuthContext);
  const { confirmEmail } = useContext(AuthContext);
  const search = new URLSearchParams(location.search);

  const handleConfirmation = async ({ email, password }) => {
    try {
      setError();
      await confirmEmail({ email, password, token: search.get("token") });
      // todo: redirect or display some message  setImmediate(() => history.push("/"));
      setImmediate(() => history.push("/"));
    } catch (e) {
      setError(
        e._dartException.error.detail || e._dartException.error.toString()
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(),
            password: Yup.string().required().min(6),
          })}
          onSubmit={handleConfirmation}
        >
          {({ isSubmitting }) => (
            <Form>
              <h6>{t("confirm.title")}</h6>
              <FormInput
                name="email"
                label={t("confirm.email")}
                className={styles.input}
                autoComplete="email"
              />
              <FormInput
                name="password"
                label={t("confirm.password")}
                type="password"
                className={styles.input}
                autoComplete="password"
              />

              {error && <div className={styles.error}>{error}</div>}
              <Button
                type="submit"
                disabled={isSubmitting}
                className={styles.button}
              >
                {t("confirm.confirm")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default withRouter(ConfirmEmailPage);
