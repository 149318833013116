import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import Button from "src/components/Inputs/Button";

import styles from "./styles.module.scss";
import FormSelect from "src/components/Inputs/Formik/FormSelect";

const NETWORKS = [
  {
    id: "indicio-testnet",
    label: "Indicio Test",
  },
  {
    id: "indicio-demonet",
    label: "Indicio Demo",
  },
  {
    id: "sovrin-builder",
    label: "Sovrin Builder",
  },
  {
    id: "sovrin-staging",
    label: "Sovrin Staging",
  },
  {
    id: "sovrin-live",
    label: "Sovrin Live",
  },
];
const OrganizationForm = ({ networks, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={{
            name: "",
            imageUri: "",
            network: NETWORKS.filter((net) => networks.includes(net.id))[0].id,
            webhookUri: "test",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            network: Yup.string().required(),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput
                name="name"
                label={t("createOrganization.name")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FormSelect
                name="network"
                label={t("createOrganization.network")}
                disabled={isSubmitting}
                options={NETWORKS.filter((net) => networks.includes(net.id))}
                className={styles.input}
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                className={styles.button}
              >
                {isSubmitting
                  ? t("createOrganization.creating")
                  : t("createOrganization.create")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OrganizationForm;
