import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Button from "src/components/Inputs/Button";

import styles from "./styles.module.scss";
import FormSelect from "src/components/Inputs/Formik/FormSelect";
import { useConnections } from "src/services/hooks/connections";
import {
  useCreateProofRequestFromTemplate,
  useProofTemplates,
} from "src/services/hooks/verifications";
import FormInput from "src/components/Inputs/Formik/FormInput";

const LABEL_WIDTH = 200;

const CreateProofRequest = ({ connectionId, templateId, info, onSubmit }) => {
  const { t } = useTranslation();

  const { data: connections } = useConnections();
  const { data: proofTemplates = [] } = useProofTemplates();

  const { mutateAsync: createProofRequestFromTemplate } =
    useCreateProofRequestFromTemplate();

  const submitForm = async (values) => {
    onSubmit(await createProofRequestFromTemplate(values));
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Formik
          initialValues={{
            connectionId,
            templateId,
            info: "",
          }}
          validationSchema={Yup.object().shape({
            connectionId: Yup.string(),
            templateId: Yup.string().required(),
          })}
          onSubmit={submitForm}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={styles.form}>
              <FormSelect
                horizontal
                labelWidth={LABEL_WIDTH}
                name="connectionId"
                label={t("createProofRequest.connectionId")}
                disabled={isSubmitting}
                className={styles.input}
                options={[
                  { id: "", label: "Connectionless" },
                  ...(connections || [])
                    .filter(({ state }) => state === "Connected")
                    .map(({ id, name: label = id }) => ({
                      id,
                      label,
                    })),
                ]}
              />
              <FormSelect
                horizontal
                disabled={isSubmitting}
                labelWidth={LABEL_WIDTH}
                label={t("createProofRequest.templateId")}
                name="templateId"
                placeholder="Select proof template"
                options={proofTemplates.map(({ id, name }) => ({
                  id,
                  label: name || id,
                }))}
              />
              <FormInput
                horizontal
                labelWidth={LABEL_WIDTH}
                label={t("createProofRequest.info")}
                name="info"
                className={styles.inputContainer}
                inputClassName={styles.input}
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                className={styles.button}
              >
                {isSubmitting
                  ? t("createProofRequest.saveLoading")
                  : t("createProofRequest.save")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateProofRequest;
