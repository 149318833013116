import factory from "./factory";

const accountApi = factory({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/account/`,
});

const accountRequest = (params) =>
  accountApi({ ...params, authToken: sessionStorage.AUTH_TOKEN });

export default accountRequest;
