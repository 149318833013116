import React from "react";
import OrganizationCard from "./OrganizationCard";
import { MdAdd } from "react-icons/md";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import Button from "src/components/Inputs/Button";
import InfoLabel from "src/components/Layout/InfoLabel";

const OrganizationsList = ({
  loading = true,
  organizations,
  onAddOrganization,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>{t("organizations.title")}</div>
        <InfoLabel>{t("organizations.add")}</InfoLabel>
        <Button variant="icon-button" onClick={onAddOrganization}>
          <MdAdd size={18} />
        </Button>
      </div>

      <div className={styles.list}>
        {loading &&
          new Array(2)
            .fill(0)
            .map((i, index) => (
              <OrganizationCard key={`loading-org-card-${index}`} loading />
            ))}
        {!loading && (
          <>
            {organizations?.length > 0 &&
              organizations.map((o) => (
                <OrganizationCard key={o.id} organization={o} />
              ))}
            {!organizations?.length && (
              <div className={styles.emptyState}>
                <div>{t("organizations.emptyState")}</div>
                <Button onClick={onAddOrganization}>
                  {t("organizations.add")}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrganizationsList;
