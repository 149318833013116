import factory from "./factory";

const responseTransform = (response) => response.data;

const errorTransform = (error) => {
  throw new Error({
    code: error?.response?.status,
    message: error?.response?.data || "An error has occurred",
  });
};
const organizationRequest = factory({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/organization/`,
  responseTransform,
  errorTransform,
});

export default organizationRequest;
