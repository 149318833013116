import authRequest from "src/services/request/authRequest";

export const register = (data) =>
  authRequest({
    method: "post",
    url: `register`,
    data,
  });

export const confirmEmail = (data) =>
  authRequest({
    method: "post",
    url: `confirmation/${encodeURIComponent(data.email)}`,
    data,
  });

export const getAccessToken = (data) =>
  authRequest({
    method: "post",
    url: `token`,
    data,
  });
