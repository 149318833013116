import React from "react";
import useAsync, { EMPTY_STATE } from "src/hooks/useAsync";
import {
  createWebhookFactory,
  deleteWebhookByIdFactory,
  getWebhooksFactory,
  updateWebhookFactory,
} from "src/api/webhooks";

export const WebhooksContext = React.createContext({
  webhooks: EMPTY_STATE,
});

export const WebhooksProvider = ({ accessToken, children }) => {
  const getWebhooks = React.useCallback(
    (...params) => getWebhooksFactory({ accessToken })(...params),
    [accessToken]
  );

  const createWebhookCall = React.useCallback(
    (...params) => createWebhookFactory({ accessToken })(...params),
    [accessToken]
  );

  const updateWebhookCall = React.useCallback(
    (...params) => updateWebhookFactory({ accessToken })(...params),
    [accessToken]
  );

  const deleteWebhookCall = React.useCallback(
    (...params) => deleteWebhookByIdFactory({ accessToken })(...params),
    [accessToken]
  );

  const webhooks = useAsync(getWebhooks, true);
  const createWebhook = useAsync(createWebhookCall, true);
  const updateWebhook = useAsync(updateWebhookCall, true);
  const deleteWebhook = useAsync(deleteWebhookCall, true);

  return (
    <WebhooksContext.Provider
      value={{
        webhooks,
        createWebhook,
        deleteWebhook,
        updateWebhook,
        reset: () => {
          webhooks.reset();
          createWebhook.reset();
          deleteWebhook.reset();
          updateWebhook.reset();
        },
      }}
    >
      {children}
    </WebhooksContext.Provider>
  );
};
