import React from "react";
import ReactModal from "react-modal";
import classNames from "classnames";
import { MdClose } from "react-icons/md";

import styles from "./styles.module.scss";

ReactModal.setAppElement("#root");

const Modal = ({
  className,
  overlayClassName,
  children,
  onClose,
  ...props
}) => (
  <ReactModal
    className={classNames(styles.modal, className)}
    overlayClassName={classNames(styles.overlay, overlayClassName)}
    {...props}
  >
    {onClose && (
      <MdClose className={styles.close} onClick={onClose} size={20} />
    )}
    {children}
  </ReactModal>
);

export default Modal;
