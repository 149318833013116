import React, { useContext, useState } from "react";
import CredentialDefinitionsTable from "./CredentialDefinitionsTable";
import CreateCredentialDefinitionModal from "src/containers/Modals/CreateCredentialDefinitionModal";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Button from "src/components/Inputs/Button";
import { MdRefresh } from "react-icons/md";
import { useHydratedCredentialDefinitions } from "src/services/hooks/definitions";
import { ModalContext } from "src/providers/ModalProvider";

const CredentialDefinitionsPage = ({ history, location }) => {
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const { t } = useTranslation();

  const {
    data: credentialDefinitions,
    error,
    isLoading: loading,
    refetch: loadCredentials,
  } = useHydratedCredentialDefinitions();
  const { toggleModal } = useContext(ModalContext);

  const toggleCreateOfferModal = ({ id: credentialDefinitionId }) => {
    toggleModal("offer-credential", { credentialDefinitionId });
  };

  const onCreateCredentialDefinition = async () => {
    toggleModal("create-credential-definition", {
      onSubmit: () => {
        loadCredentials();
      },
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{t("credentials.title")}</h4>

        <Button onClick={onCreateCredentialDefinition}>
          {t("credentials.add").toUpperCase()}
        </Button>
        <Button
          className={styles.refresh}
          variant="outlined"
          onClick={() => loadCredentials()}
        >
          <MdRefresh size={22} />
        </Button>
      </div>
      <div className={styles.subContainer}>
        <div className={styles.info}>{t("credentials.info")}</div>
        {error && <div>{error?.message || error.toString()}</div>}
        {!error && (
          <div className={styles.table}>
            <CredentialDefinitionsTable
              loading={loading}
              items={credentialDefinitions}
              onCreateOffer={toggleCreateOfferModal}
              onItemClicked={({ id }) =>
                history.push(`${location.pathname}/definitions/${id}`)
              }
            />
          </div>
        )}
      </div>
      {createModalIsOpen && (
        <CreateCredentialDefinitionModal
          isOpen={createModalIsOpen}
          setIsOpen={setCreateModalIsOpen}
          onSubmit={() => loadCredentials()}
        />
      )}
    </div>
  );
};

export default CredentialDefinitionsPage;
