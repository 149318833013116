import React from "react";
import styles from "./styles.module.scss";

const DetailsSectionHeader = ({ title, children }) => (
  <div className={styles.detailsSectionHeaderContainer}>
    <div className={styles.detailsSectionHeader}>{title}</div>
    {children}
  </div>
);

export default DetailsSectionHeader;
