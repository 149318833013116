import React from "react";
import { Dropdown } from "react-overlays";
import Toggle from "src/components/Dropdown/Toggle";
import Menu from "src/components/Dropdown/Menu";

import styles from "./styles.module.scss";

const DropdownButton = ({
  show,
  onToggle,
  drop,
  alignEnd,
  title,
  role,
  children,
}) => (
  <div className={styles.container}>
    <Dropdown show={show} onToggle={onToggle} drop={drop} alignEnd={alignEnd}>
      {({ props }) => (
        <div {...props} className={styles.dropdown}>
          <Toggle>{title}</Toggle>
          <Menu role={role}>{children}</Menu>
        </div>
      )}
    </Dropdown>
  </div>
);

export default DropdownButton;
