import React, { useState } from "react";
import styles from "./styles.module.scss";
import FormInput from "src/components/Inputs/Formik/FormInput";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { MdClose } from "react-icons/md";

import { useTranslation } from "react-i18next";
import FormSelect from "src/components/Inputs/Formik/FormSelect";
import {
  useHydratedCredentialDefinitions,
  useSchemas,
} from "src/services/hooks/definitions";
import { useFormikContext } from "formik";

const Restriction = ({ name, onRemove }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { data: credentialDefinitions } = useHydratedCredentialDefinitions();
  const { data: schemas } = useSchemas();
  const { setFieldValue } = useFormikContext();

  const selectSchema = (e) => {
    setFieldValue(`${name}.schema_id`, e.target.value);
  };
  const selectCredDefId = (e) => {
    setFieldValue(`${name}.cred_def_id`, e.target.value);
  };
  const handleChangeSchema = (e) => {
    setFieldValue(`${name}.schema`, "");
    setFieldValue(`${name}.schema_id`, e.target.value);
  };
  const handleChangeCredDefId = (e) => {
    setFieldValue(`${name}.cred_def`, "");
    setFieldValue(`${name}.cred_def_id`, e.target.value);
  };
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.restrictionContainerTitle}>
          {t("createProofTemplate.attribute.restriction.restrictionTitle")}
        </div>
        <div className={styles.expand} onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? (
            <MdArrowDropDown size={22} />
          ) : (
            <MdArrowDropUp size={22} />
          )}
        </div>
        <div className={styles.close} onClick={onRemove}>
          <MdClose size={22} />
        </div>
      </div>
      {!collapsed && (
        <>
          <FormSelect
            name={`${name}.cred_def`}
            label={t(
              "createProofTemplate.attribute.restriction.credentialDefinitionName"
            )}
            className={styles.inputContainer}
            options={[
              { id: "", label: "" },
              ...(credentialDefinitions || []).map(({ id, schema }) => ({
                id,
                label: schema.name,
              })),
            ]}
            onChange={(e) => selectCredDefId(e)}
          />
          <FormInput
            label={t(
              "createProofTemplate.attribute.restriction.credentialDefinitionId"
            )}
            name={`${name}.cred_def_id`}
            className={styles.inputContainer}
            onChange={(e) => handleChangeCredDefId(e)}
          />
          <FormSelect
            name={`${name}.schema`}
            label={t("createCredentialDefinition.schemaName")}
            className={styles.inputContainer}
            options={[
              { id: "", label: "" },
              ...(schemas || []).map(({ id, name: label }) => ({ id, label })),
            ]}
            onChange={(e) => selectSchema(e)}
          />
          <FormInput
            label={t("createProofTemplate.attribute.restriction.schemaId")}
            name={`${name}.schema_id`}
            className={styles.inputContainer}
            onChange={(e) => handleChangeSchema(e)}
          />
          <FormInput
            label={t("createProofTemplate.attribute.restriction.schemaName")}
            name={`${name}.schema_name`}
            className={styles.inputContainer}
          />
          <FormInput
            label={t("createProofTemplate.attribute.restriction.schemaVersion")}
            name={`${name}.schema_version`}
            className={styles.inputContainer}
          />
          <FormInput
            label={t(
              "createProofTemplate.attribute.restriction.schemaIssuerDid"
            )}
            name={`${name}.schema_issuer_did`}
            className={styles.inputContainer}
          />
          <FormInput
            label={t("createProofTemplate.attribute.restriction.attributeName")}
            name={`${name}.attribute_value.name`}
            className={styles.inputContainer}
          />
          <FormInput
            label={t(
              "createProofTemplate.attribute.restriction.attributeValue"
            )}
            name={`${name}.attribute_value.value`}
            className={styles.inputContainer}
          />
          <FormInput
            label={t("createProofTemplate.attribute.restriction.issuerDid")}
            name={`${name}.issuer_did`}
            className={styles.inputContainer}
          />
        </>
      )}
    </div>
  );
};

export default Restriction;
