import React from "react";
import styles from "./styles.module.scss";
import Button from "src/components/Inputs/Button";
import Step from "./Step";
import { useTranslation } from "react-i18next";

const Steps = ({ name, items = [], onAdd, onRemove, errors, touched }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {items.map(({ id }, index) => (
        <div key={index}>
          <Step
            key={id}
            name={`${name}.${index}`}
            onRemove={index === 0 ? null : () => onRemove(index)}
          />
        </div>
      ))}
      {touched && errors && (
        <div className={styles.error}>
          {Array.isArray(errors) ? "One of the items has errors" : errors}
        </div>
      )}

      <Button className={styles.addButton} type="button" onClick={onAdd}>
        {t("interactionFlowForm.addStep")}
      </Button>
    </div>
  );
};

export default Steps;
