import React from "react";

import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "src/components/Inputs/Button";
import logo from "src/assets/logo.svg";

const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img src={logo} alt="logo" height={30} />
        <Link to="/login/credential">
          <Button>{t("login.withCredential")}</Button>
        </Link>
        <Link to="/login/password">
          <Button variant="outlined">{t("login.withPassword")}</Button>
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
