import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "src/providers/AuthProvider";
import ClockLoader from "react-spinners/ClockLoader";

import styles from "./styles.module.scss";

const PrivateRoute = ({ ...props }) => {
  const { isSignedIn, isInitializing } = useContext(AuthContext);

  if (isInitializing) {
    return (
      <div className={styles.loading}>
        <ClockLoader color="#2f44c1" size={150} />
      </div>
    );
  }
  return isSignedIn ? <Route {...props} /> : <Redirect to="/login" />;
};
export default PrivateRoute;
