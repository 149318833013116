import React from "react";
import { Route, Switch } from "react-router-dom";
import LeftMenu from "src/components/LeftMenu";
import ClockLoader from "react-spinners/ClockLoader";

import styles from "./styles.module.scss";
import OrganizationPage from "src/containers/OrganizationPage";
import ConnectionsPage from "src/containers/ConnectionsPage";
import CredentialDefinitionsPage from "src/containers/CredentialDefinitionsPage";
import CredentialDefinitionPage from "src/containers/CredentialDefinitionPage";
import VerificationsPage from "src/containers/VerificationsPage";
import OAuthScopesPage from "src/containers/OAuthScopesPage";
import VerificationPage from "src/containers/VerificationPage";
import InteractionFlowsPage from "src/containers/InteractionFlowsPage";
import ConnectionPage from "src/containers/ConnectionPage";
import WebhooksPage from "src/containers/WebhooksPage";
import { OAuthScopesProvider } from "src/providers/OAuthScopesProvider";
import { ProofTemplatesProvider } from "src/providers/ProofTemplatesProvider";
import { ProofTemplateProvider } from "src/providers/ProofTemplateProvider";
import { WebhooksProvider } from "src/providers/WebhooksProvider";
import { useOrganizationById } from "src/services/hooks/organizations";
import { ModalProvider } from "src/providers/ModalProvider";

const OrganizationRoutes = ({
  match: { params: { organizationId } = {} } = {},
}) => {
  const {
    isLoading: loading,
    data,
    error,
  } = useOrganizationById(organizationId);

  if (error) {
    return (
      <div className={styles.container}>
        <LeftMenu />
        <div>
          {error.title ||
            "There was an error when trying to load the organization"}
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <LeftMenu />
      {data?.accessToken && !loading ? (
        <ProofTemplatesProvider accessToken={data?.accessToken}>
          <ProofTemplateProvider accessToken={data?.accessToken}>
            <WebhooksProvider accessToken={data?.accessToken}>
              <OAuthScopesProvider accessToken={data?.accessToken}>
                <ModalProvider>
                  <Switch>
                    <Route
                      path="/organizations/:organizationId"
                      exact
                      component={OrganizationPage}
                    />
                    <Route
                      path="/organizations/:organizationId/oauth-scopes"
                      component={OAuthScopesPage}
                    />
                    <Route
                      path="/organizations/:organizationId/interaction-flows"
                      component={InteractionFlowsPage}
                    />
                    <Route
                      path="/organizations/:organizationId/connections/:connectionId"
                      component={ConnectionPage}
                    />
                    <Route
                      path="/organizations/:organizationId/connections"
                      component={ConnectionsPage}
                    />
                    <Route
                      path="/organizations/:organizationId/credentials/definitions/:credentialDefinitionId"
                      component={CredentialDefinitionPage}
                    />
                    <Route
                      path="/organizations/:organizationId/credentials"
                      component={CredentialDefinitionsPage}
                    />
                    <Route
                      path="/organizations/:organizationId/interaactin"
                      component={CredentialDefinitionsPage}
                    />
                    <Route
                      path="/organizations/:organizationId/verifications/:proofTemplateId"
                      component={VerificationPage}
                    />
                    <Route
                      path="/organizations/:organizationId/verifications"
                      exact
                      component={VerificationsPage}
                    />
                    <Route
                      path="/organizations/:organizationId/webhooks"
                      component={WebhooksPage}
                    />
                  </Switch>
                </ModalProvider>
              </OAuthScopesProvider>
            </WebhooksProvider>
          </ProofTemplateProvider>
        </ProofTemplatesProvider>
      ) : (
        <div className={styles.loading}>
          <ClockLoader color="#2f44c1" size={150} />
        </div>
      )}
    </div>
  );
};

export default OrganizationRoutes;
