import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createCredentialOfferFactory,
  getCredentialsFactory,
} from "src/api/credentials";

import { useOrganizationFromContext } from "src/services/hooks/state";
import { CREDENTIAL_DEFINITIONS_QUERY } from "src/services/hooks/definitions";

export const useCredentialsByCredentialDefinitionId = (id) => {
  const organization = useOrganizationFromContext();

  return useQuery(
    `organizations/${organization?.id}/${CREDENTIAL_DEFINITIONS_QUERY}/${id}/credentials`,
    () =>
      getCredentialsFactory({ accessToken: organization?.accessToken })({
        credentialDefinitionId: id,
      })
  );
};

export const useCreateCredentialOffer = () => {
  const queryClient = useQueryClient();
  const organization = useOrganizationFromContext();
  return useMutation(
    createCredentialOfferFactory({ accessToken: organization?.accessToken }),
    {
      onMutate: ({ credentialDefinitionId }) => {
        return { credentialDefinitionId };
      },
      onSuccess: (data, { credentialDefinitionId }) => {
        // Invalidate and refetch
        queryClient.invalidateQueries(
          `organizations/${organization?.id}/${CREDENTIAL_DEFINITIONS_QUERY}/${credentialDefinitionId}/credentials`
        );
      },
    }
  );
};
