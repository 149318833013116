import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Select = ({ className, options, placeholder, ...props }) => (
  <select className={classNames(styles.input, className)} {...props}>
    {(placeholder
      ? [{ id: "", label: placeholder }, ...(options || [])]
      : options
    )?.map(({ id, label }) => (
      <option key={id} value={id}>
        {label}
      </option>
    ))}
  </select>
);

export default Select;
