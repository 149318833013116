import React, { createContext, useState } from "react";
import { Exception } from "sass";
import {
  register as registerRequest,
  getAccessToken as getAccessTokenRequest,
  confirmEmail as confirmEmailRequest,
  getAccessToken,
} from "src/api/auth";

export const AuthContext = createContext();
export const withAuth = (Component) => (props) =>
  (
    <AuthConsumer>
      {(providerValue) => <Component {...props} auth={{ ...providerValue }} />}
    </AuthConsumer>
  );

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = (props) => {
  const AUTH_TOKEN = "AUTH_TOKEN";
  const [authState, setAuthState] = useState(getInitialState());

  function getInitialState() {
    var token = sessionStorage.getItem(AUTH_TOKEN);
    // todo validate token and extract user info from token
    var isTokenValid = !!token;
    return { isSignedIn: isTokenValid };
  }

  const register = registerRequest;

  const confirmEmail = async ({ email, password, token }) => {
    // read token if auth is ok
    var response = await confirmEmailRequest({ email, password, token });
    if (response.status === "success" && response.data.token) {
      updateAuthState({ isSignedIn: true, token: response.data.token });
    } else {
      // todo throw exception with meaningful error info
      throw new Exception(response);
    }
  };

  const signOut = () => {
    updateAuthStateForSignOut({ isSignedIn: false });
  };

  // todo for signIn we need to invoke getTokenRequest and store the result
  const signIn = async ({ email, password }) => {
    // read token if auth is ok
    var response = await getAccessTokenRequest({ email, password });
    if (response.status === "success" && response.data.token) {
      updateAuthState({ isSignedIn: true, token: response.data.token });
    } else {
      // todo throw exception with meaningful error info
      throw new Exception(response);
    }
  };

  const updateAuthState = (newState) => {
    sessionStorage.setItem(AUTH_TOKEN, newState?.token);
    setAuthState(newState);
  };

  const updateAuthStateForSignOut = (newState) => {
    sessionStorage.clear();
    setAuthState(newState);
  };

  // useEffect(() => {
  //   // check if this is a sign-in link
  //   if (authState.isInitializing && isSignInLink()) {
  //     // extract user's email and try to log them in
  //     const params = new URLSearchParams(window.location.search);
  //     const email = params.get("email");
  //     if (email) {
  //       auth
  //         .signInWithEmailLink(email)
  //         .then((cred) => {
  //           console.debug("SIGNED IN", JSON.stringify(cred));
  //         })
  //         .catch((err) => {
  //           console.warn("UNABLE TO SIGN IN", JSON.stringify(err));
  //           // sign out so we redirect to login page
  //           auth.signOut();
  //         });
  //     }
  //   }

  //   if (!authUnsubscribeRef.current) {
  //     console.debug("Subscribing to auth.onIdTokenChanged");
  //     authUnsubscribeRef.current = auth.onIdTokenChanged(
  //       async (user, error) => {
  //         if (error) {
  //           console.warn("AUTH ERROR");
  //         }
  //         if (user) {
  //           const idToken = await user.getIdTokenResult();
  //           // store user, token and whatever else we want
  //           // based on custom criteria (claims in our case)
  //           updateAuthState({
  //             isSignedIn: true,
  //             user,
  //             idToken,
  //             signOut: () => auth.signOut(),
  //           });
  //         } else if (!user) {
  //           updateAuthState({ isSignedIn: false });
  //         }
  //       }
  //     );
  //   }
  //   return () => {
  //     if (authUnsubscribeRef.current) {
  //       console.debug("Unsubscribing auth.onIdTokenChanged");
  //       authUnsubscribeRef.current();
  //       authUnsubscribeRef.current = null;
  //     }
  //   };
  // }, []); //eslint-disable-line
  // // here above we ignore dependency on effect so cleanup only runs on unmount

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        register,
        confirmEmail,
        getAccessToken,
        signIn,
        signOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
