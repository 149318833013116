import React, { useContext } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import Button from "src/components/Inputs/Button";
import FormSelect from "src/components/Inputs/Formik/FormSelect";
import { useProofTemplates } from "src/services/hooks/verifications";

import styles from "./styles.module.scss";
import { OAuthScopesContext } from "src/providers/OAuthScopesProvider";

const OAuthScopeForm = ({ scope, onSubmit }) => {
  const { t } = useTranslation();

  const { data: proofTemplates } = useProofTemplates();
  const {
    createScope: { execute: createScope },
    updateScope: { execute: updateScope },
  } = useContext(OAuthScopesContext);

  const submitForm = async (values) => {
    const handler = values.id ? updateScope : createScope;
    const {
      data = {},
      status,
      error,
    } = await handler({
      ...values,
      redirectUris: values.redirectUris.split(","),
    });
    if (status !== "success") {
      console.error(error);
      return;
    }
    onSubmit(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={
            scope
              ? {
                  ...scope,
                  redirectUris: scope.redirectUris
                    ? scope.redirectUris.join(",")
                    : "",
                }
              : {
                  name: "",
                  proofTemplateId: "",
                  audience: "",
                  redirectUris: "",
                }
          }
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            audience: Yup.string().required(),
            proofTemplateId: Yup.string().required(),
            redirectUris: Yup.string().required(),
          })}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormSelect
                name="proofTemplateId"
                label={t("saveOAuthScope.proofTemplateId")}
                disabled={isSubmitting}
                className={styles.input}
                options={[
                  { id: "", label: "Proof Templates" },
                  ...(proofTemplates || []).map(({ id, name: label = id }) => ({
                    id,
                    label,
                  })),
                ]}
              />
              <FormInput
                name="name"
                label={t("saveOAuthScope.name")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FormInput
                name="audience"
                label={t("saveOAuthScope.audience")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FormInput
                name="redirectUris"
                label={t("saveOAuthScope.redirectUrls")}
                disabled={isSubmitting}
                className={styles.input}
              />

              <Button
                type="submit"
                disabled={isSubmitting}
                className={styles.button}
              >
                {isSubmitting
                  ? t("saveOAuthScope.saving")
                  : t("saveOAuthScope.save")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OAuthScopeForm;
