import React from "react";
import useAsync, { EMPTY_STATE } from "src/hooks/useAsync";
import {
  createOAuthScopeFactory,
  updateOAuthScopeFactory,
  deleteOAuthScopeFactory,
  getOAuthScopesFactory,
} from "src/api/oAuthScopes";

export const OAuthScopesContext = React.createContext({
  scopes: EMPTY_STATE,
});

export const OAuthScopesProvider = ({ accessToken, children }) => {
  const getOAuthScopes = React.useCallback(
    (...params) => getOAuthScopesFactory({ accessToken })(...params),
    [accessToken]
  );
  const createOAuthScope = React.useCallback(
    (...params) => createOAuthScopeFactory({ accessToken })(...params),
    [accessToken]
  );
  const updateOAuthScope = React.useCallback(
    (...params) => updateOAuthScopeFactory({ accessToken })(...params),
    [accessToken]
  );
  const deleteOAuthScope = React.useCallback(
    (...params) => deleteOAuthScopeFactory({ accessToken })(...params),
    [accessToken]
  );
  const scopes = useAsync(getOAuthScopes, true);
  const createScope = useAsync(createOAuthScope, true);
  const updateScope = useAsync(updateOAuthScope, true);
  const deleteScope = useAsync(deleteOAuthScope, true);

  return (
    <OAuthScopesContext.Provider
      value={{
        scopes,
        createScope,
        updateScope,
        deleteScope,
        reset: () => {
          scopes.reset();
          createScope.reset();
          updateScope.reset();
          deleteScope.reset();
        },
      }}
    >
      {children}
    </OAuthScopesContext.Provider>
  );
};
