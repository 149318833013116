import React, { useContext } from "react";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import RecordLogTable from "src/containers/ConnectionPage/RecordLogTable";
import CollapsibleLayout from "src/components/Layout/CollapsibleLayout";
import DetailsSectionHeader from "src/components/Layout/DetailsSectionHeader";
import DetailsRowDisplay from "src/components/Layout/DetailsRowDisplay";
import { RecordTypes } from "src/services/constants";
import Button from "src/components/Inputs/Button";
import { MdRefresh } from "react-icons/md";
import {
  useConnectionById,
  useConnectionRecordLogsById,
  useDeleteConnections,
} from "src/services/hooks/connections";
import { ModalContext } from "src/providers/ModalProvider";

const ConnectionPage = ({
  history,
  match: {
    params: { organizationId, connectionId },
  },
}) => {
  const { t } = useTranslation();
  const { data: connection, isLoading: loading } =
    useConnectionById(connectionId);

  const {
    data: recordLogs,
    isLoading: recordLogsLoading,
    refetch: getRecordLogs,
  } = useConnectionRecordLogsById(connectionId);

  const { mutate: deleteConnections, isLoading: deleteLoading } =
    useDeleteConnections();
  const { toggleModal } = useContext(ModalContext);

  if (loading || !connection) {
    return <div>Loading...</div>;
  }
  const onDetailsClicked = ({ typeName, id, credentialDefinitionId }) => {
    switch (typeName) {
      case RecordTypes.CREDENTIAL:
        history.push(
          `/organizations/${organizationId}/credentials/definitions/${credentialDefinitionId}`
        );
        break;
      case RecordTypes.CONNECTION:
        history.push(`/organizations/${organizationId}/connections/${id}`);
        break;
      // no default
    }
  };
  const onDeleteConnection = async () => {
    deleteConnections([connectionId], {
      onSuccess: () =>
        history.push(`/organizations/${organizationId}/connections`),
    });
  };

  const onToggleCreateOfferModal = () => {
    toggleModal("offer-credential", { connectionId });
  };

  const onCreateProofRequest = async () => {
    toggleModal("create-proof-request", { connectionId });
  };

  const onCreateMessage = async () => {
    toggleModal("create-message", { connectionId });
  };

  return (
    <div className={styles.container}>
      <h4>{connection.alias?.name || connection.id}</h4>
      <CollapsibleLayout>
        <div>
          <DetailsSectionHeader title={t("connection.details")} />
          <DetailsRowDisplay
            label={t("connection.connectionId")}
            value={connection.id}
          />
          <DetailsRowDisplay
            label={t("connection.state")}
            value={connection.state}
          />
          <DetailsRowDisplay
            label={t("connection.did")}
            value={connection.did}
          />
          <DetailsRowDisplay
            label={t("connection.key")}
            value={connection.key}
          />

          <DetailsSectionHeader title={t("connection.peerDetails")} />
          <DetailsRowDisplay
            label={t("connection.alias")}
            value={connection.alias}
          />
          <DetailsRowDisplay
            label={t("connection.did")}
            value={connection.peerDid}
          />
          <DetailsRowDisplay
            label={t("connection.key")}
            value={connection.peerKey}
          />
          <DetailsRowDisplay
            label={t("connection.endpointUri")}
            value={connection.endpoint?.uri}
          />
          <DetailsRowDisplay
            label={t("connection.endpointKey")}
            value={connection.endpoint?.keys}
          />

          <Button
            color="warning"
            onClick={onDeleteConnection}
            disabled={deleteLoading}
          >
            {(deleteLoading
              ? t("connection.deleting")
              : t("connection.delete")
            ).toUpperCase()}
          </Button>
        </div>
        <div>
          <DetailsSectionHeader title={t("connection.recordLog")}>
            <div className={styles.actions}>
              {connection.state === "Connected" && (
                <>
                  <Button onClick={onToggleCreateOfferModal}>
                    {t("connection.issue").toUpperCase()}
                  </Button>
                  <Button
                    className={styles.actionButton}
                    onClick={onCreateProofRequest}
                  >
                    {t("connection.request").toUpperCase()}
                  </Button>
                  <Button
                    className={styles.actionButton}
                    onClick={onCreateMessage}
                  >
                    {t("connection.message").toUpperCase()}
                  </Button>
                </>
              )}
              <Button
                className={styles.refresh}
                variant="outlined"
                onClick={() => getRecordLogs()}
              >
                <MdRefresh size={22} />
              </Button>
            </div>
          </DetailsSectionHeader>
          <RecordLogTable
            loading={recordLogsLoading}
            items={recordLogs}
            onDetailsClicked={onDetailsClicked}
          />
        </div>
      </CollapsibleLayout>
    </div>
  );
};

export default ConnectionPage;
