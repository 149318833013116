import React from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Inputs/Button";
import Attribute from "./Attribute";
import styles from "./styles.module.scss";

const Attributes = ({ name, items, onAdd, onRemove, errors, touched }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {items.map(({ id }, index) => (
        <div key={index}>
          <Attribute
            key={id}
            name={`${name}.${index}`}
            onRemove={index === 0 ? null : () => onRemove(index)}
          />
        </div>
      ))}
      {touched && errors && (
        <div className={styles.error}>
          {Array.isArray(errors) ? "One of the items has errors" : errors}
        </div>
      )}

      <Button className={styles.addButton} type="button" onClick={onAdd}>
        {t("createProofTemplate.addAttribute")}
      </Button>
    </div>
  );
};

export default Attributes;
