import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const Welcome = ({ name }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.title}>
      {name ? t("welcome.name", { name }) : t("welcome")}
    </div>
  );
};

export default Welcome;
