import React from "react";
import Table, {
  TableRow,
  TableRowCell,
  Tbody,
  Thead,
  TheadCell,
  TheadRow,
} from "src/components/Table";

import { useTable } from "react-table";

import styles from "./styles.module.scss";
import { MdDelete, MdEdit } from "react-icons/md";

const OAuthScopesTable = ({
  onItemClicked,
  loading,
  items,
  onEdit,
  onDelete,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Proof Template",
        accessor: "proofTemplateId",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Audience",
        accessor: "audience",
      },
      {
        Header: "Redirect Urls",
        accessor: ({ redirectUris }) => (redirectUris || []).join(", "),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ row }) => (
          <div>
            <MdEdit onClick={() => onEdit && onEdit(row.original)} />
            <MdDelete onClick={() => onDelete && onDelete(row.original)} />
          </div>
        ),
      },
    ],
    [onDelete, onEdit]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: items || [] });

  return (
    <Table
      loading={loading}
      isEmpty={!items?.length}
      className={styles.table}
      {...getTableProps()}
    >
      <Thead>
        {headerGroups.map((headerGroup) => (
          <TheadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TheadCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </TheadCell>
            ))}
          </TheadRow>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow
              {...row.getRowProps()}
              onClick={() => onItemClicked && onItemClicked(row.original)}
            >
              {row.cells.map((cell) => {
                return (
                  <TableRowCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableRowCell>
                );
              })}
            </TableRow>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default OAuthScopesTable;
