import React from "react";
import moment from "moment-timezone";
import Table, {
  TableRow,
  TableRowCell,
  Thead,
  TheadCell,
  TheadRow,
  Tbody,
} from "src/components/Table";

import { useTable } from "react-table";

import styles from "./styles.module.scss";
import DisplayWithCopy from "src/components/DisplayWithCopy";
import StateFilter from "./StateFilter";
import Checkbox from "src/components/Inputs/Checkbox";

const ConnectionsTable = ({
  onItemClicked,
  loading,
  items,
  selected,
  setSelected,
  filters,
  setFilters,
}) => {
  // const setStateFilter = (state) => setFilters({ ...filters, state });

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <Checkbox
            checked={(items || []).every(({ id }) => selected[id])}
            onCheckedChanged={(all) =>
              setSelected(
                all
                  ? (items || []).reduce(
                      (res, { id }) => ({ ...res, [id]: true }),
                      {}
                    )
                  : {}
              )
            }
          />
        ),
        accessor: "selected",
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Checkbox
            checked={selected[id]}
            onCheckedChanged={(value) =>
              setSelected({ ...selected, [id]: value })
            }
          />
        ),
        className: styles.checkbox,
        headerClassName: styles.checkbox,
      },
      {
        Header: "Connection Name",
        accessor: "alias.name",
        Cell: ({ row }) => (
          <div
            className={styles.name}
            onClick={() => onItemClicked && onItemClicked(row.original)}
          >
            {row.original?.name || row.original?.id}
          </div>
        ),
      },
      {
        Header: "Last Event",
        accessor: "updatedAtUtc",
        Cell: ({ row }) => (
          <div>
            {moment(
              row.original?.updatedAtUtc || row.original?.createdAtUtc
            ).format("L LTS")}
          </div>
        ),
        className: styles.date,
        headerClassName: styles.date,
      },
      {
        Header: "Connection ID",
        accessor: "id",
        Cell: ({ row }) => <DisplayWithCopy value={row.original?.id} />,
      },
      {
        Header: () => (
          <StateFilter
            value={filters?.state}
            onChange={(state) => setFilters({ ...filters, state })}
          />
        ),
        accessor: "state",
        className: styles.status,
        headerClassName: styles.status,
      },
    ],
    [filters, selected, items, onItemClicked, setSelected, setFilters]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: items || [] });

  return (
    <Table
      loading={loading}
      isEmpty={!items?.length}
      className={styles.table}
      {...getTableProps()}
    >
      <Thead>
        {headerGroups.map((headerGroup) => (
          <TheadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <TheadCell
                  {...column.getHeaderProps()}
                  className={column.headerClassName}
                >
                  {column.render("Header")}
                </TheadCell>
              );
            })}
          </TheadRow>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableRowCell
                    {...cell.getCellProps()}
                    className={cell.column?.className}
                  >
                    {cell.render("Cell")}
                  </TableRowCell>
                );
              })}
            </TableRow>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default ConnectionsTable;
