import organizationRequest from "src/services/request/organizationRequest";

export const getWebhooksFactory = ({ accessToken }) => () =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/webhooks`,
  });

export const deleteWebhookByIdFactory = ({ accessToken }) => (id) =>
  organizationRequest({
    authToken: accessToken,
    method: "delete",
    url: `v1/webhooks/${id}`,
  });

export const createWebhookFactory = ({ accessToken }) => (data) =>
  organizationRequest({
    authToken: accessToken,
    method: "post",
    url: `v1/webhooks`,
    data,
  });

export const updateWebhookFactory = ({ accessToken }) => (data) =>
  organizationRequest({
    authToken: accessToken,
    method: "put",
    url: `v1/webhooks/${data.id}`,
    data,
  });
