import accountRequest from "src/services/request/qAccountRequest";

export const getOrganizationById = (id) =>
  accountRequest({
    method: "get",
    url: `organizations/${id}`,
  });

export const deleteOrganizationById = (id) =>
  accountRequest({
    method: "delete",
    url: `organizations/${id}`,
  });

export const getOrganizations = () =>
  accountRequest({
    method: "get",
    url: "organizations",
  });

export const createOrganization = (data) =>
  accountRequest({
    method: "post",
    url: "organizations",
    data,
  });
