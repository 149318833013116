import React from "react";

import styles from "./styles.module.scss";

const InfoItem = ({ label, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{children}</div>
    </div>
  );
};

export default InfoItem;
