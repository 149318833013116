import React, { useMemo } from "react";
import Table, {
  TableRow,
  TableRowCell,
  Tbody,
  Thead,
  TheadCell,
  TheadRow,
} from "src/components/Table";

import { useTable } from "react-table";

import styles from "./styles.module.scss";
import Button from "src/components/Inputs/Button";
import { RecordTypes } from "src/services/constants";
import moment from "moment-timezone";
const TypeLabels = {
  [RecordTypes.CONNECTION]: "Connection",
  [RecordTypes.CREDENTIAL]: "Credential",
  [RecordTypes.PROOF]: "Proof Request",
  [RecordTypes.MESSAGE]: "Text Message",
};

const AllowedDetails = [RecordTypes.CONNECTION, RecordTypes.CREDENTIAL];

const RecordLogTable = ({ onDetailsClicked, loading, items }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdAtUtc",
        Cell: ({ row }) => (
          <div>
            {moment(
              row.original?.updatedAtUtc || row.original?.createdAtUtc
            ).format("L LTS")}
          </div>
        ),
      },
      {
        Header: "Type",
        accessor: ({ typeName }) => TypeLabels[typeName],
      },
      {
        Header: "Status",
        accessor: ({ state }) => state,
      },
      {
        Header: "Message",
        accessor: ({ message }) => message,
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ row }) => (
          <>
            {AllowedDetails.includes(row.original.typeName) && (
              <Button
                variant="tableText"
                onClick={() => onDetailsClicked(row.original)}
              >
                Details
              </Button>
            )}
          </>
        ),
      },
    ],
    [onDetailsClicked]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: items || [] });

  return (
    <Table
      loading={loading}
      isEmpty={!items?.length}
      className={styles.table}
      {...getTableProps()}
    >
      <Thead>
        {headerGroups.map((headerGroup) => (
          <TheadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TheadCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </TheadCell>
            ))}
          </TheadRow>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableRowCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableRowCell>
                );
              })}
            </TableRow>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default RecordLogTable;
