import React from "react";
import useAsync, { EMPTY_STATE } from "src/hooks/useAsync";
import { getProofTemplateByIdFactory } from "src/api/proofTemplates";
import { getProofRecordsFactory } from "src/api/proofs";

export const ProofTemplateContext = React.createContext({
  proofTemplates: EMPTY_STATE,
});

export const ProofTemplateProvider = ({ accessToken, children }) => {
  const getProofTemplateById = React.useCallback(
    (...params) => getProofTemplateByIdFactory({ accessToken })(...params),
    [accessToken]
  );

  const getProofRecords = React.useCallback(
    (...params) => getProofRecordsFactory({ accessToken })(...params),
    [accessToken]
  );

  const proofTemplate = useAsync(getProofTemplateById, true);
  const { execute: loadProofRecords, ...proofRecords } = useAsync(
    getProofRecords,
    true
  );

  return (
    <ProofTemplateContext.Provider
      value={{
        proofTemplate: {
          ...proofTemplate,
          execute: async (id) => {
            await Promise.all([
              proofTemplate.execute(id),
              loadProofRecords({ templateId: id }),
            ]);
          },
        },
        proofRecords,
        reset: () => {
          proofTemplate.reset();
        },
      }}
    >
      {children}
    </ProofTemplateContext.Provider>
  );
};
