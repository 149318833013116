import React from "react";
import Modal from "src/components/Modal";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import CreateMessage from "./CreateMessage";

const CreateMessageModal = ({ connectionId, text, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  let close = () => {
    setIsOpen(false);
  };
  const onMessageSent = ({ uri } = {}) => {
    if (uri === null) {
      setIsOpen(false);
    } else {
      console.log("");
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>{t("createMessage.modalTitle")}</h4>
          <CreateMessage
            connectionId={connectionId}
            text={text}
            onSubmit={onMessageSent}
          />
        </div>
      )}
    </Modal>
  );
};

export default CreateMessageModal;
