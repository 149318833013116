import React, { useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import styles from "./styles.module.scss";

const CollapsibleLayout = ({ children: [left, right] = [] }) => {
  const [collapsed, setCollapsed] = useState();
  const CollapseButton = collapsed ? MdKeyboardArrowRight : MdKeyboardArrowLeft;
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <CollapseButton
          className={styles.collapse}
          size={22}
          onClick={() => setCollapsed(!collapsed)}
        />
        {!collapsed && <div className={styles.leftBody}>{left}</div>}
      </div>
      <div className={styles.right}>{right}</div>
    </div>
  );
};

export default CollapsibleLayout;
