import React from "react";
import classNames from "classnames";
import { useDropdownMenu } from "react-overlays";

import styles from "./styles.module.scss";

export const MenuItem = ({ onClose, onClick, children }) => (
  <div
    onClick={(e) => {
      if (onClick) onClick(e);
      if (onClose) onClose(e);
    }}
    className={styles.item}
  >
    {children}
  </div>
);

const Menu = ({ role, children }) => {
  const { show, close, props } = useDropdownMenu({
    flip: true,
    offset: [0, 8],
  });

  return (
    <div
      {...props}
      className={classNames(props.className, styles.container, {
        [styles.show]: show,
      })}
      role={role}
    >
      {(Array.isArray(children) ? children : [children]).map((child) =>
        React.cloneElement(child, { onClose: close })
      )}
    </div>
  );
};

export default Menu;
