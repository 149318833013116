import React, { Fragment as Fallback, useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import LoginPage from "src/containers/LoginPage";
import ConfirmEmailPage from "src/containers/ConfirmEmailPage";
import PasswordLoginPage from "src/containers/LoginPage/PasswordLogin";
import CredentialLoginPage from "src/containers/LoginPage/CredentialLogin";
import AccountSettingsPage from "src/containers/AccountSettingsPage";
import RegisterPage from "src/containers/RegisterPage";
import DashboardPage from "src/containers/DashboardPage";
import PrivateRoute from "src/components/PrivateRoute";
import { AuthContext } from "src/providers/AuthProvider";
import PageWithHeader from "src/components/Layout/PageWithHeader";
import OrganizationRoutes from "src/containers/OrganizationRoutes";
import { AccountProvider } from "src/providers/AccountProvider";

const App = () => {
  const { isSignedIn } = useContext(AuthContext);
  return (
    <Router>
      <AccountProvider>
        <PageWithHeader>
          <ToastContainer />
          <Switch>
            {isSignedIn ? (
              <Redirect path="/login" to="/" />
            ) : (
              <>
                <Route path="/auth" exact component={ConfirmEmailPage} />
                <Route path="/login" exact component={LoginPage} />
                <Route path="/login/password" component={PasswordLoginPage} />
                <Route
                  path="/login/credential"
                  component={CredentialLoginPage}
                />
                <Route path="/register" component={RegisterPage} />
              </>
            )}
            <PrivateRoute exact path="/" component={DashboardPage} />
            <PrivateRoute
              exact
              path="/account-settings"
              component={AccountSettingsPage}
            />
            <PrivateRoute
              path="/organizations/:organizationId"
              component={OrganizationRoutes}
            />
            <Route path="*">
              <Fallback>Not found</Fallback>
            </Route>
          </Switch>
        </PageWithHeader>
      </AccountProvider>
    </Router>
  );
};

export default App;
