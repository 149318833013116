import React from "react";
import Table, {
  TableRow,
  TableRowCell,
  Tbody,
  Thead,
  TheadCell,
  TheadRow,
} from "src/components/Table";
import { MdEdit } from "react-icons/md";

import { useTable } from "react-table";

import styles from "./styles.module.scss";
import Button from "src/components/Inputs/Button";
// import { MdInfo } from "react-icons/all";
import { MdDelete } from "react-icons/md";

const ProofTemplatesTable = ({
  onItemClicked,
  onCreateProofRequest,
  onEdit,
  onDelete,
  loading,
  items,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        Cell: ({ row }) => (
          <div
            className={styles.name}
            onClick={() => onItemClicked && onItemClicked(row.original)}
          >
            {row.original?.name || row.original?.id || "not set"}
          </div>
        ),
      },
      {
        Header: "Version",
        accessor: "version",
      },
      {
        Header: "Template ID",
        accessor: "id",
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={styles.actions}>
            <Button
              variant="tableText"
              onClick={() => onCreateProofRequest(row.original)}
            >
              Request
            </Button>
            <MdEdit
              className={styles.iconButton}
              onClick={() => onEdit && onEdit(row.original)}
            />
            {/* <MdInfo
              className={styles.iconButton}
              onClick={() => alert("not implemented")}
            /> */}
            <MdDelete
              className={styles.iconButton}
              onClick={() => onDelete && onDelete(row.original)}
            />
          </div>
        ),
      },
    ],
    [onCreateProofRequest, onDelete, onEdit, onItemClicked]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: items || [] });

  return (
    <Table
      loading={loading}
      isEmpty={!items?.length}
      className={styles.table}
      {...getTableProps()}
    >
      <Thead>
        {headerGroups.map((headerGroup) => (
          <TheadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TheadCell
                {...column.getHeaderProps()}
                className={column.headerClassName}
              >
                {column.render("Header")}
              </TheadCell>
            ))}
          </TheadRow>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableRowCell
                    {...cell.getCellProps()}
                    className={cell.column?.className}
                  >
                    {cell.render("Cell")}
                  </TableRowCell>
                );
              })}
            </TableRow>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default ProofTemplatesTable;
