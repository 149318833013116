import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import FormInput from "src/components/Inputs/Formik/FormInput";
import { useTranslation } from "react-i18next";
import FormSelect from "src/components/Inputs/Formik/FormSelect";
import { FieldArray, useFormikContext } from "formik";
import { MdClose } from "react-icons/md";
import {
  useHydratedCredentialDefinitions,
  useSchemas,
} from "src/services/hooks/definitions";
// import { useInteractionFlows } from "src/services/hooks/definitions";
import { useProofTemplates } from "src/services/hooks/verifications";

const optionsAction = [
  { id: "request-connection", label: "Request Connection" },
  { id: "issue-credential", label: "Issue Credential" },
  { id: "request-proof", label: "Request Proof" },
];

const optionsTransport = [
  { id: "url", label: "Url" },
  { id: "qr.svg", label: "QR Code (SVG)" },
  { id: "qr.png", label: "QR Code (PNG)" },
  // { value: "Email", label: "Email" },
  { id: "connection", label: "Connection" },
];

const CredentialMapping = ({
  fieldName,
  step,
  touched,
  errors,
  arrayHelpers,
}) => {
  const { t } = useTranslation();
  const { data: schemas = [] } = useSchemas();

  const removeCredentialMapping = async (index) => {
    arrayHelpers.remove(index);
  };
  const { setFieldValue } = useFormikContext();

  const { data: credentialDefinitions = [] } =
    useHydratedCredentialDefinitions();

  const buildCredentialAttributes = (schemaId) => {
    const schema = schemas.find(({ id }) => id === schemaId);
    return schema?.attributeNames
      ? schema.attributeNames.map((name) => ({
          name,
          value: "",
          "mime-type": "text/plain",
        }))
      : [];
  };

  useEffect(() => {
    if (
      step.action === "issue-credential" &&
      (step.credentialMapping.length === 0 ||
        step.credentialMapping[step.credentialMapping.length - 1].id !== "")
    ) {
      step.credentialMapping.push({ id: "", source: "", attributes: {} });
    }
  }, [step]);

  return (
    <div className={styles.containerCredentialDefinitonIds}>
      {step.credentialMapping?.map((value, index) => (
        <div className={styles.header} key={index}>
          <div className={styles.containerAttributeMapping}>
            <div className={styles.header}>
              <FormSelect
                name={`${fieldName}.credentialMapping.${index}.id `}
                label={t("interactionFlowForm.step.credentialAttributeMapping")}
                placeholder="Select credential definition"
                onChange={({ target: { value } }) => {
                  setFieldValue(
                    `${fieldName}.credentialMapping.${index}.id`,
                    value
                  );
                  const newSchemaId = (credentialDefinitions || []).find(
                    ({ id }) => id === value
                  )?.schemaId;
                  setFieldValue(
                    `${fieldName}.credentialMapping.${index}.schemaId`,
                    newSchemaId
                  );
                  setFieldValue(
                    `${fieldName}.credentialMapping.${index}.attributes`,
                    buildCredentialAttributes(newSchemaId)
                  );
                }}
                className={styles.inputContainer}
                inputClassName={styles.inputCredentialMapping}
                options={credentialDefinitions.map(({ id, schema }) => ({
                  id,
                  label: schema.name,
                }))}
              />
              {index < step.credentialMapping.length - 1 && (
                <div
                  className={styles.close}
                  onClick={() => removeCredentialMapping(index)}
                >
                  <MdClose size={22} />
                </div>
              )}
            </div>
            {index < step.credentialMapping.length - 1 && (
              <div className={styles.attributeMappingSection}>
                {step.credentialMapping[index]?.attributes?.map(
                  ({ name: attrName }) => (
                    <FormInput
                      key={`${attrName}`}
                      name={`${fieldName}.credentialMapping.${index}.attributes.${step.credentialMapping}.${attrName}`}
                      label={attrName}
                      inputClassName={styles.inputCredentialMapping}
                      className={styles.inputContainer}
                    />
                  )
                )}
                <FormInput
                  key={index}
                  name={`${fieldName}.credentialMapping.${index}.source`}
                  label={"Input Context"}
                  inputClassName={styles.input}
                  className={styles.inputContainer}
                />
              </div>
            )}
          </div>
        </div>
      ))}
      {touched && errors && (
        <div className={styles.error}>
          {Array.isArray(errors) ? "One of the items has errors" : errors}
        </div>
      )}
    </div>
  );
};

const Step = ({ name, onRemove }) => {
  const { t } = useTranslation();
  const { getFieldProps } = useFormikContext();

  const { value: step } = getFieldProps(name);
  // const { data: interactionFlows } = useInteractionFlows();
  const { data: proofTemplates } = useProofTemplates();

  return (
    <div className={styles.container}>
      {onRemove && (
        <div className={styles.closeAttribute} onClick={onRemove}>
          <MdClose size={22} />
        </div>
      )}
      <FormSelect
        label={t("interactionFlowForm.step.action")}
        name={`${name}.action`}
        className={styles.inputContainer}
        inputClassName={styles.input}
        options={optionsAction}
      />
      <FormSelect
        label={t("interactionFlowForm.step.transport")}
        name={`${name}.transport`}
        className={styles.inputContainer}
        inputClassName={styles.input}
        options={optionsTransport}
      />
      {step.action === "issue-credential" && (
        <FieldArray name={`${name}.credentialMapping`}>
          {({ ...arrayHelpers }) => (
            <CredentialMapping
              fieldName={name}
              step={step}
              arrayHelpers={arrayHelpers}
              className={styles.inputContainer}
              inputClassName={styles.input}
            />
          )}
        </FieldArray>
      )}
      {/* <FormInput
        label={t("interactionFlowForm.step.emailTemplateId")}
        name={`${name}.emailTemplateId`}
        className={styles.inputContainer}
        inputClassName={styles.input}
      /> */}
      {step.action === "request-proof" && (
        <FormSelect
          label={t("interactionFlowForm.step.proofTemplateId")}
          name={`${name}.proofTemplateId`}
          className={styles.inputContainer}
          inputClassName={styles.input}
          options={proofTemplates?.map(({ id, name: label }) => ({
            id,
            label,
          }))}
        />
      )}
      <FormInput
        type="number"
        label={t("interactionFlowForm.step.timeoutMapping")}
        name={`${name}.timeoutMinutes`}
        className={styles.inputContainer}
        inputClassName={styles.input}
      />
      <FormInput
        type="checkbox"
        label={t("interactionFlowForm.step.isManualStep")}
        name={`${name}.isManualStep`}
        className={styles.inputContainer}
        inputClassName={styles.input}
      />
    </div>
  );
};

export default Step;
