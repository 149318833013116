import React from "react";
import QRCode from "src/components/QRCode";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const CredentialLogin = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h6>{t("credentialLogin.title")}</h6>
        <QRCode content={"test"} />
        <div>{t("credentialLogin.info")}</div>
      </div>
    </div>
  );
};

export default CredentialLogin;
