import organizationRequest from "src/services/request/qOrganizationRequest";

export const getMessageFactory =
  ({ accessToken }) =>
  (params) =>
    organizationRequest({
      authToken: accessToken,
      method: "get",
      url: `v1/messages/events`,
      params,
    });

export const createMessageFactory =
  ({ accessToken }) =>
  (data = {}) =>
    organizationRequest({
      authToken: accessToken,
      method: "post",
      url: `v1/messages`,
      data,
    });
