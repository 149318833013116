import React from "react";
import Table, {
  TableRow,
  TableRowCell,
  Tbody,
  Thead,
  TheadCell,
  TheadRow,
} from "src/components/Table";

import { useTable } from "react-table";
import moment from "moment";

import styles from "./styles.module.scss";

const CredentialsTable = ({ onItemClicked, loading, items }) => {
  items = items?.sort(
    (date1, date2) =>
      new Date(date2.createdAtUtc).getTime() -
      new Date(date1.createdAtUtc).getTime()
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Connection ID",
        accessor: "connectionId",
      },
      {
        Header: "Date",
        accessor: "createdAtUtc",
        Cell: ({ row }) => (
          <div>
            {moment
              .utc(row.original?.updatedAtUtc || row.original?.createdAtUtc)
              .local()
              .format("L LTS")}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "state",
      },
    ],
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: items || [] });

  return (
    <Table
      loading={loading}
      isEmpty={!items?.length}
      className={styles.table}
      {...getTableProps()}
    >
      <Thead>
        {headerGroups.map((headerGroup) => (
          <TheadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TheadCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </TheadCell>
            ))}
          </TheadRow>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow
              {...row.getRowProps()}
              onClick={() => onItemClicked && onItemClicked(row.original)}
            >
              {row.cells.map((cell) => {
                return (
                  <TableRowCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableRowCell>
                );
              })}
            </TableRow>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default CredentialsTable;
