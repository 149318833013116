import React from "react";
import Header from "src/components/Header";

import styles from "./styles.module.scss";

const PageWithHeader = ({ children }) => (
  <div className={styles.container}>
    <Header />
    <div className={styles.body}>{children}</div>
  </div>
);

export default PageWithHeader;
