import organizationRequest from "src/services/request/organizationRequest";

export const getOAuthScopesFactory =
  ({ accessToken }) =>
  () =>
    organizationRequest({
      authToken: accessToken,
      method: "get",
      url: `v1/oauth-scopes`,
    });

export const deleteOAuthScopeFactory =
  ({ accessToken }) =>
  (id) =>
    organizationRequest({
      authToken: accessToken,
      method: "delete",
      url: `v1/oauth-scopes/${id}`,
    });

export const createOAuthScopeFactory =
  ({ accessToken }) =>
  (data) =>
    organizationRequest({
      authToken: accessToken,
      method: "post",
      url: `v1/oauth-scopes`,
      data,
    });

export const updateOAuthScopeFactory =
  ({ accessToken }) =>
  (data) =>
    organizationRequest({
      authToken: accessToken,
      method: "put",
      url: `v1/oauth-scopes/${data.id}`,
      data,
    });
