import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "src/containers/App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import AuthProvider from "src/providers/AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";

import "react-toastify/dist/ReactToastify.min.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
