import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Thead = ({ className, ...props }) => (
  <div {...props} className={classNames(className, styles.container)} />
);

export default Thead;
