import React, { useState } from "react";
import Modal from "src/components/Modal";
import CreateProofRequest from "./CreateProofRequest";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import QRCode from "src/components/QRCode";
import DisplayWithCopy from "src/components/DisplayWithCopy";

const CreateProofRequestModal = ({
  connectionId,
  templateId,
  isOpen,
  setIsOpen,
}) => {
  const [proofRequestUri, setProofRequestUri] = useState();
  const { t } = useTranslation();
  let close = () => {
    setIsOpen(false);
  };
  const onProofRequestCreated = ({ uri } = {}) => {
    if (uri === null) {
      setIsOpen(false);
    } else {
      setProofRequestUri(uri);
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>{t("createProofRequest.modalTitle")}</h4>
          {proofRequestUri ? (
            <>
              <QRCode content={proofRequestUri} />
              <div className={styles.copy}>
                <label>Copy link</label>
                <div className={styles.copyValue}>
                  <DisplayWithCopy value={proofRequestUri} />
                </div>
              </div>
            </>
          ) : (
            <CreateProofRequest
              connectionId={connectionId}
              templateId={templateId}
              onSubmit={onProofRequestCreated}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default CreateProofRequestModal;
