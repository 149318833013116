import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Button from "src/components/Inputs/Button";

import styles from "./styles.module.scss";
import FormSelect from "src/components/Inputs/Formik/FormSelect";
import FormInput from "src/components/Inputs/Formik/FormInput";
import { useConnections } from "src/services/hooks/connections";
import { useCreateCredentialOffer } from "src/services/hooks/credentials";
import {
  useHydratedCredentialDefinitions,
  useSchemas,
} from "src/services/hooks/definitions";

const LABEL_WIDTH = 200;

const CredentialOfferForm = ({
  connectionId,
  credentialDefinition,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { data: connections } = useConnections();
  const { data: schemas = [] } = useSchemas();

  const { mutateAsync: createCredentialOffer } = useCreateCredentialOffer();
  const { data: credentialDefinitions = [] } =
    useHydratedCredentialDefinitions();

  const submitForm = async (values) => {
    onSubmit(
      await createCredentialOffer({
        ...values,
        credentialAttributes: JSON.stringify(values.credentialAttributes),
      })
    );
  };
  const buildCredentialAttributes = (schemaId) => {
    const schema = schemas.find(({ id }) => id === schemaId);
    return schema?.attributeNames
      ? schema.attributeNames.map((name) => ({
          name,
          value: "",
          "mime-type": "text/plain",
        }))
      : [];
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Formik
          initialValues={{
            connectionId,
            credentialDefinitionId: credentialDefinition?.id,
            schemaId: credentialDefinition?.schemaId,
            credentialAttributes: buildCredentialAttributes(
              credentialDefinition?.schemaId
            ),
          }}
          validationSchema={Yup.object().shape({
            connectionId: Yup.string(),
            credentialDefinitionId: Yup.string().required(),
            schemaId: Yup.string().required(),
          })}
          onSubmit={submitForm}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className={styles.form}>
              <FormSelect
                key="connections"
                horizontal
                labelWidth={LABEL_WIDTH}
                name="connectionId"
                label={t("credentialOffer.connectionId")}
                disabled={isSubmitting}
                className={styles.input}
                options={[
                  { id: "", label: "Connectionless" },
                  ...(connections || [])
                    .filter(({ state }) => state === "Connected")
                    .map(({ id, name: label = id }) => ({
                      value: id,
                      label,
                    })),
                ]}
              />

              <FormSelect
                key="credentials"
                horizontal
                labelWidth={LABEL_WIDTH}
                name="credentialDefinitionId"
                placeholder="Select credential definition"
                onChange={({ target: { value } }) => {
                  setFieldValue("credentialDefinitionId", value);
                  const newSchemaId = (credentialDefinitions || []).find(
                    ({ id }) => id === value
                  )?.schemaId;
                  setFieldValue("schemaId", newSchemaId);
                  setFieldValue(
                    "credentialAttributes",
                    buildCredentialAttributes(newSchemaId)
                  );
                }}
                label={t("credentialOffer.credentialDefinitionId")}
                className={styles.input}
                options={credentialDefinitions.map(({ id, schema }) => ({
                  id,
                  label: schema?.name + " ( " + schema?.version + " )",
                }))}
              />

              <FormSelect
                key="schemas"
                horizontal
                labelWidth={LABEL_WIDTH}
                name="schemaId"
                disabled
                placeholder="Select credential definition"
                label={t("credentialOffer.schemaId")}
                className={styles.input}
                options={schemas.map(({ id, name: label }) => ({ id, label }))}
              />

              <label className={styles.sectionHeader}>
                {t("credentialOffer.credentialAttributes")}
              </label>
              {(values.credentialAttributes || []).map(({ name }, index) => (
                <FormInput
                  horizontal
                  labelWidth={LABEL_WIDTH}
                  key={`${name}-${index}`}
                  name={`credentialAttributes.${index}.value`}
                  label={name}
                  inputClassName={styles.input}
                />
              ))}

              <Button
                type="submit"
                disabled={isSubmitting}
                className={styles.button}
              >
                {isSubmitting
                  ? t("credentialOffer.saveLoading")
                  : t("credentialOffer.save")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CredentialOfferForm;
