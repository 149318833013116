import React, { useState } from "react";
import Modal from "src/components/Modal";

import OrganizationForm from "../OrganizationForm";

import styles from "./styles.module.scss";
import Button from "src/components/Inputs/Button";
import { useTranslation } from "react-i18next";
import DisplayWithCopy from "src/components/DisplayWithCopy";
import { useCreateOrganization } from "src/services/hooks/organizations";

const CreateOrganizationModal = ({ networks, isOpen, setIsOpen, onSubmit }) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [createdOrganization, setCreatedOrganization] = useState();
  let close = () => setIsOpen(false);

  const { mutate: create } = useCreateOrganization();

  const submitForm = (organization) => {
    create(organization, {
      onSuccess: (data) => {
        setStep(1);
        setCreatedOrganization(data);
      },
    });
  };

  const submitModal = () => {
    onSubmit(createdOrganization);
    close();
  };
  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>{t("createOrganization.modalTitle")}</h4>
          {step === 0 && (
            <OrganizationForm networks={networks} onSubmit={submitForm} />
          )}
          {step === 1 && (
            <div>
              <div>{t("createOrganization.reviewAccessToken")}</div>
              <div className={styles.copyAccessToken}>
                <DisplayWithCopy value={createdOrganization?.accessToken} />
              </div>
              <Button className={styles.submitModal} onClick={submitModal}>
                {t("createOrganization.submitModal")}
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default CreateOrganizationModal;
