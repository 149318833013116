import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Tbody = ({ className, headerHeight = 50, ...props }) => (
  <div
    {...props}
    className={classNames(className, styles.container)}
    style={{
      height: headerHeight > 0 ? `calc(100% - ${headerHeight})` : "100%",
    }}
  />
);

export default Tbody;
