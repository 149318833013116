import React, { useState } from "react";
import InviteConnectionModal from "src/containers/Modals/InviteConnectionModal";
import CredentialOfferModal from "src/containers/Modals/CredentialOfferModal";
import CreateProofTemplateModal from "src/containers/Modals/CreateProofTemplateModal";
import CreateCredentialDefinitionModal from "src/containers/Modals/CreateCredentialDefinitionModal";
//import { updateProofTemplateFactory } from "src/containers/Modals/CreateProofTemplateModal"
import CreateProofRequestModal from "src/containers/Modals/CreateProofRequestModal";
import CreateMessageModal from "src/containers/Modals/CreateMessageModal";
//import { updateProofTemplateFactory } from "src/api/proofTemplates";
const modalMap = {
  "invite-connection": InviteConnectionModal,
  "offer-credential": CredentialOfferModal,
  "proof-template": CreateProofTemplateModal,
  "create-credential-definition": CreateCredentialDefinitionModal,
  //"update-proof-template": CreateProofTemplateModal,
  "create-proof-request": CreateProofRequestModal,
  "create-message": CreateMessageModal,
};

export const ModalContext = React.createContext({});

export const ModalProvider = ({ children }) => {
  const [toggled, setToggled] = useState();
  const [props, setProps] = useState({});
  const [type, setType] = useState();
  const toggle = (type, props = {}) => {
    setToggled(true);
    setType(type);
    setProps(props);
  };

  let modal = undefined;
  if (toggled && modalMap[type]) {
    const Component = modalMap[type];
    // todo: create withBaseModal hoc that handles isOpen and onSubmit
    modal = (
      <Component
        isOpen={toggled}
        setIsOpen={(newValue) => {
          setToggled(newValue);
          if (!newValue) {
            setProps();
            setType();
          }
        }}
        {...(props || {})}
      />
    );
  }

  return (
    <ModalContext.Provider
      value={{
        toggle,
        toggleModal: toggle,
      }}
    >
      {children}
      {modal}
    </ModalContext.Provider>
  );
};
