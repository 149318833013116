import organizationRequest from "src/services/request/qOrganizationRequest";

export const getCredentialDefinitionsFactory = ({ accessToken }) => () =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/definitions/credentials`,
  });

export const getCredentialDefinitionByIdFactory = ({ accessToken }) => (id) =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/definitions/credentials/${id}`,
  });

export const createCredentialDefinitionFactory = ({ accessToken }) => (data) =>
  organizationRequest({
    authToken: accessToken,
    method: "post",
    url: `v1/definitions/credentials`,
    data,
  });

export const getSchemasFactory = ({ accessToken }) => () =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/definitions/schemas`,
  });

export const createSchemaFactory = ({ accessToken }) => (data) =>
  organizationRequest({
    authToken: accessToken,
    method: "post",
    url: `v1/definitions/schemas`,
    data,
  });

export const getInteractionFlowsFactory = ({ accessToken }) => (params) =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/definitions/interactions`,
    params,
  });

export const deleteInteractionFlowFactory = ({ accessToken }) => (id) =>
  organizationRequest({
    authToken: accessToken,
    method: "delete",
    url: `v1/definitions/interactions/${id}`,
  });

export const createInteractionFlowFactory = ({ accessToken }) => (data) =>
  organizationRequest({
    authToken: accessToken,
    method: "post",
    url: `v1/definitions/interactions`,
    data,
  });

export const updateInteractionFlowFactory = ({ accessToken }) => (data) =>
  organizationRequest({
    authToken: accessToken,
    method: "put",
    url: `v1/definitions/interactions/${data.id}`,
    data,
  });
