import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import styles from "./styles.module.scss";

const Item = ({ route, isActive, labelKey, svg, icon: Icon }) => {
  const { t } = useTranslation();
  return (
    <NavLink
      className={styles.container}
      activeClassName={styles.active}
      isActive={isActive}
      exact
      to={route}
    >
      {svg && (
        <div
          style={{ mask: `url(${svg})`, WebkitMask: `url(${svg})` }}
          className={styles.icon}
        />
      )}
      {Icon && <Icon className={styles.reactIcon} />}
      <div className={styles.label}>{t(labelKey)}</div>
    </NavLink>
  );
};

export default Item;
