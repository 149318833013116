import organizationRequest from "src/services/request/qOrganizationRequest";

export const getProofTemplatesFactory = ({ accessToken }) => () =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/proof/templates`,
  });

export const getProofTemplateByIdFactory = ({ accessToken }) => (id) =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/proof/templates/${id}`,
  });

export const createProofTemplateFactory = ({ accessToken }) => (data) =>
  organizationRequest({
    authToken: accessToken,
    method: "post",
    url: `v1/proof/templates`,
    data,
  });

export const deleteProofTemplateFactory = ({ accessToken }) => (id) =>
  organizationRequest({
    authToken: accessToken,
    method: "delete",
    url: `v1/proof/templates/${id}`,
  });

export const updateProofTemplateFactory = ({ accessToken }) => (data) =>
  organizationRequest({
    authToken: accessToken,
    method: "put",
    url: `v1/proof/templates/${data.id}`,
    data,
  });
