import React from "react";
import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import styles from "./styles.module.scss";
import { useOrganizationById } from "src/services/hooks/organizations";

const OrganizationBreadcrumb = ({
  match: {
    params: { organizationId },
  },
}) => {
  const { isLoading: loading, data: organization } =
    useOrganizationById(organizationId);

  if (loading) {
    return "Loading...";
  }
  if (organization?.id !== organizationId) {
    return "Undefined Organization";
  }

  return organization.name;
};

const routes = [
  {
    path: "/account-settings",
    breadcrumb: "Account Settings",
  },
  {
    path: "/organizations/:organizationId",
    breadcrumb: OrganizationBreadcrumb,
  },
  {
    path: "/organizations/:organizationId/connections/:connectionId",
    breadcrumb: "Connection",
  },
  {
    path: "/organizations/:organizationId/credentials/definitions/:credentialDefinitionId",
    breadcrumb: "Credential Definition",
  },
  {
    path: "/organizations/:organizationId/oauth-scopes",
    breadcrumb: "OAuth Scopes",
  },
  {
    path: "/organizations/:organizationId/interactions/definitions",
    breadcrumb: "Interaction Flows",
  },
];

const Breadcrumbs = () => {
  // todo: add translation
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: [
      "/login",
      "/login/password",
      "/register",
      "/organizations",
      "/organizations/:organizationId/credentials/definitions/",
    ],
  });

  return breadcrumbs.length <= 1 ? (
    <React.Fragment />
  ) : (
    <div className={styles.breadcrumbs}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <div key={match.url} className={styles.item}>
          {index < breadcrumbs.length - 1 ? (
            <>
              <NavLink className={styles.link} to={match.url}>
                {breadcrumb}
              </NavLink>
              <div className={styles.separator}>/</div>
            </>
          ) : (
            <div className={styles.lastItem}>{breadcrumb}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumbs;
