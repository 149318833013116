import React from "react";
import Modal from "src/components/Modal";
import OAuthScopeForm from "./OAuthScopeForm";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const OAuthScopeModal = ({ scope, isOpen, setIsOpen, onSubmit }) => {
  const { t } = useTranslation();
  let close = () => {
    setIsOpen(false);
  };

  const submitModal = (values) => {
    onSubmit();
    close();
  };
  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>{t("saveOAuthScope.modalTitle")}</h4>
          <OAuthScopeForm scope={scope} onSubmit={submitModal} />
        </div>
      )}
    </Modal>
  );
};

export default OAuthScopeModal;
