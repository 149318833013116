import React, { useEffect } from "react";
import { Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import Button from "src/components/Inputs/Button";
import FormSelect from "src/components/Inputs/Formik/FormSelect";
import { MdClose } from "react-icons/md";

import styles from "./styles.module.scss";

const optionsDetailLevel = [
  { id: "Minimal", label: "Minimal" },
  { id: "Compact", label: "Compact" },
  { id: "Full", label: "Full" },
  { id: "Default", label: "Default" },
];

const optionsEventType = [
  { id: "", label: "Any" },
  { id: "System", label: "System" },
  { id: "Connection", label: "Connection" },
  { id: "Credential", label: "Credential" },
  { id: "Proof", label: "Proof" },
  { id: "Message", label: "Message" },
  { id: "Interaction", label: "Interaction" },
  { id: "DidComm", label: "DidComm" },
];

const TagFilter = ({ webhook, touched, errors, arrayHelpers }) => {
  const { t } = useTranslation();

  const removeTag = async (index) => {
    arrayHelpers.remove(index);
  };

  useEffect(() => {
    if (
      webhook.tagFilter.length === 0 ||
      webhook.tagFilter[webhook.tagFilter.length - 1] !== ""
    ) {
      arrayHelpers.push("");
    }
  }, [webhook, arrayHelpers]);

  return (
    <div className={styles.container}>
      <div className={styles.tagFilterTitle}>
        {t("createWebhook.tagFilter")}
      </div>
      {webhook.tagFilter.map((value, index) => (
        <div className={styles.header} key={index}>
          <FormInput
            name={`tagFilter.${index}`}
            inputClassName={styles.inputTagFilter}
          />
          {index < webhook.tagFilter.length - 1 && (
            <div className={styles.close} onClick={() => removeTag(index)}>
              <MdClose size={22} />
            </div>
          )}
        </div>
      ))}
      {touched && errors && (
        <div className={styles.error}>
          {Array.isArray(errors) ? "One of the items has errors" : errors}
        </div>
      )}
    </div>
  );
};

const WebhookForm = ({ webhook, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={
            webhook || {
              url: "",
              active: false,
              detailLevel: "default",
              eventType: "",
              statusFilter: "",
              tagFilter: [""],
            }
          }
          validationSchema={Yup.object().shape({
            url: Yup.string().url().required(),
            detailLevel: Yup.string().required(),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput
                name="url"
                label={t("createWebhook.url")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FormSelect
                name="detailLevel"
                label={t("createWebhook.detailLevel")}
                disabled={isSubmitting}
                className={styles.input}
                options={optionsDetailLevel}
              />
              <FormSelect
                name="eventType"
                label={t("createWebhook.eventType")}
                disabled={isSubmitting}
                className={styles.input}
                options={optionsEventType}
              />
              <FormInput
                name="statusFilter"
                label={t("createWebhook.statusFilter")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FieldArray name="tagFilter">
                {({ form: { values: data }, ...arrayHelpers }) => (
                  <TagFilter
                    webhook={data}
                    disabled={isSubmitting}
                    arrayHelpers={arrayHelpers}
                    className={styles.input}
                  />
                )}
              </FieldArray>
              <FormInput
                type="checkbox"
                name="active"
                label={t("createWebhook.active")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                className={styles.button}
              >
                {isSubmitting
                  ? t("createWebhook.saving")
                  : t("createWebhook.save")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WebhookForm;
