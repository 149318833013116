import React, { useState } from "react";
import Modal from "src/components/Modal";
import CredentialOfferForm from "./CredentialOfferForm";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useCredentialDefinitionById } from "src/services/hooks/definitions";
import QRCode from "src/components/QRCode";

const CredentialOfferModal = ({
  connectionId,
  credentialDefinitionId,
  isOpen,
  setIsOpen,
  onSubmit = () => { },
}) => {
  const { t } = useTranslation();
  let close = () => {
    setIsOpen(false);
  };

  const [credentialOfferUri, setCredentialOfferUri] = useState();

  const onCredentialOfferCreated = ({ uri } = {}) => {
    if (uri === null) {
      setIsOpen(false);
    } else {
      setCredentialOfferUri(uri);
    }
  };

  const {
    data: credentialDefinition /*, isLoading: credentialDefinitionLoading*/,
  } = useCredentialDefinitionById(credentialDefinitionId);

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>{t("credentialOffer.modalTitle")}</h4>
          {/* {credentialDefinitionLoading ? (
            <div>Loading...</div>
          ) : (
            <CredentialOfferForm
              connectionId={connectionId}
              credentialDefinition={credentialDefinition}
              onSubmit={submitModal}
            />
          )} */}
          {credentialOfferUri ? (
            <QRCode content={credentialOfferUri} />
          ) : (
            <CredentialOfferForm
              connectionId={connectionId}
              credentialDefinition={credentialDefinition}
              onSubmit={onCredentialOfferCreated}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default CredentialOfferModal;
