import organizationRequest from "src/services/request/qOrganizationRequest";

export const getCredentialsFactory = ({ accessToken }) => ({
  credentialDefinitionId,
}) =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    params: {
      credentialDefinitionId,
    },
    url: `v1/credentials`,
  });

export const createCredentialOfferFactory = ({ accessToken }) => (data) =>
  organizationRequest({
    authToken: accessToken,
    method: "post",
    url: `v1/credentials`,
    data,
  });
