import React from "react";
import useAsync, { EMPTY_STATE } from "src/hooks/useAsync";
import {
  getProofTemplatesFactory,
  createProofTemplateFactory,
  deleteProofTemplateFactory,
  updateProofTemplateFactory,
} from "src/api/proofTemplates";

export const ProofTemplatesContext = React.createContext({
  proofTemplates: EMPTY_STATE,
});

export const ProofTemplatesProvider = ({ accessToken, children }) => {
  const getProofTemplates = React.useCallback(
    (...params) => getProofTemplatesFactory({ accessToken })(...params),
    [accessToken]
  );

  const createProofTemplateCall = React.useCallback(
    (...params) => createProofTemplateFactory({ accessToken })(...params),
    [accessToken]
  );

  const updateProofTemplateCall = React.useCallback(
    (...params) => updateProofTemplateFactory({ accessToken })(...params),
    [accessToken]
  );

  const deleteProofTemplateCall = React.useCallback(
    (...params) => deleteProofTemplateFactory({ accessToken })(...params),
    [accessToken]
  );

  const proofTemplates = useAsync(getProofTemplates, true);
  const createProofTemplate = useAsync(createProofTemplateCall, true);
  const updateProofTemplate = useAsync(updateProofTemplateCall, true);
  const deleteProofTemplate = useAsync(deleteProofTemplateCall, true);

  return (
    <ProofTemplatesContext.Provider
      value={{
        proofTemplates,
        createProofTemplate,
        deleteProofTemplate,
        updateProofTemplate,
        reset: () => {
          proofTemplates.reset();
          createProofTemplate.reset();
          deleteProofTemplate.reset();
          updateProofTemplate.reset();
        },
      }}
    >
      {children}
    </ProofTemplatesContext.Provider>
  );
};
