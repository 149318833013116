import React from "react";
import Item from "./Item";
import { SiAuth0 as OAuthIcon } from "react-icons/si";
import { AiOutlineInteraction as InteractionIcon } from "react-icons/ai";
import styles from "./styles.module.scss";

import dashboardIcon from "src/assets/icon_dashboard.svg";
import organizationIcon from "src/assets/icon_organization.svg";
import connectionsIcon from "src/assets/icon_connections.svg";
import credentialsIcon from "src/assets/icon_credentials.svg";
import verificationsIcon from "src/assets/icon_verifications.svg";
import webhoooksIcon from "src/assets/icon_webhooks.svg";
import { useRouteMatch, generatePath, matchPath } from "react-router-dom";

const ITEMS = [
  {
    route: "/",
    labelKey: "menu.dashboard",
    svg: dashboardIcon,
  },
  {
    route: "/organizations/:organizationId",
    labelKey: "menu.organizationHome",
    svg: organizationIcon,
  },
  {
    route: "/organizations/:organizationId/oauth-scopes",
    labelKey: "menu.oAuthScopes",
    icon: OAuthIcon,
  },
  {
    route: "/organizations/:organizationId/interaction-flows",
    labelKey: "menu.interactionFlows",
    icon: InteractionIcon,
  },
  {
    route: "/organizations/:organizationId/connections",
    labelKey: "menu.connections",
    svg: connectionsIcon,
    isActive: (match, location) =>
      !!match ||
      !!matchPath(location.pathname, {
        path: "/organizations/:organizationId/connections/:connectionId",
        exact: false,
        strict: false,
      }),
  },
  {
    route: "/organizations/:organizationId/credentials",
    labelKey: "menu.credentials",
    svg: credentialsIcon,
    isActive: (match, location) =>
      !!match ||
      !!matchPath(location.pathname, {
        path: "/organizations/:organizationId/credentials/definitions",
        exact: false,
        strict: false,
      }),
  },
  {
    route: "/organizations/:organizationId/verifications",
    labelKey: "menu.verifications",
    svg: verificationsIcon,
    isActive: (match, location) =>
      !!match ||
      !!matchPath(location.pathname, {
        path: "/organizations/:organizationId/verifications/:proofTemplateId",
        exact: false,
        strict: false,
      }),
  },
  {
    route: "/organizations/:organizationId/webhooks",
    labelKey: "menu.webhooks",
    svg: webhoooksIcon,
  },
];

const LeftMenu = () => {
  const match = useRouteMatch();

  return (
    <div className={styles.container}>
      {ITEMS.map((item) => {
        // remap the route based on current path params
        const newItem = { ...item };
        newItem.route = generatePath(item.route, match.params);
        return <Item key={item.route} {...newItem} />;
      })}
    </div>
  );
};

export default LeftMenu;
