import organizationRequest from "src/services/request/qOrganizationRequest";

export const getConnectionsFactory = ({ accessToken }) => (params) =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/connections`,
    params,
  });

export const getConnectionByIdFactory = ({ accessToken }) => (id) =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/connections/${id}`,
  });

export const deleteConnectionByIdFactory = ({ accessToken }) => (id) =>
  organizationRequest({
    authToken: accessToken,
    method: "delete",
    url: `v1/connections/${id}`,
  });

export const getConnectionRecordLogsByConnectionIdFactory = ({
  accessToken,
}) => (id) =>
  organizationRequest({
    authToken: accessToken,
    method: "get",
    url: `v1/connections/${id}/record-logs`,
  });

export const createConnectionFactory = ({ accessToken }) => (data = {}) =>
  organizationRequest({
    authToken: accessToken,
    method: "post",
    url: `v1/connections`,
    data,
  });
