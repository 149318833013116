import React from "react";

import styles from "./styles.module.scss";

const DetailsRowDisplay = ({ label, value }) => (
  <div className={styles.container}>
    <label>{label}</label>
    <div>
      {Array.isArray(value) ? (
        value.map((attr, ix) => <div key={ix}>{attr}</div>)
      ) : (
        <span>{value}</span>
      )}
    </div>
  </div>
);

export default DetailsRowDisplay;
