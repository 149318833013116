import React from "react";

import QREncoder from "qrcode-svg";
import styles from "./styles.module.scss";

const QRCode = ({ content }) => {
  const qrSvg = new QREncoder({
    xmlDeclaration: false,
    pretty: false,
    join: true,
    ecl: "L",
    content,
  }).svg();

  return (
    <div className={styles.qrcode}>
      <img src={`data:image/svg+xml;base64,${btoa(qrSvg)}`} alt="qr code" />
    </div>
  );
};

export default QRCode;
