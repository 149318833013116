import React, { useState } from "react";
import Modal from "src/components/Modal";

import styles from "./styles.module.scss";
import Button from "src/components/Inputs/Button";

const ConfirmModal = ({
  isOpen,
  setIsOpen,
  submitText = "Ok",
  submitLoadingText = "Loading",
  cancelText = "Cancel",
  submitProps = {},
  message,
  color,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  let close = () => {
    setIsOpen(false);
  };

  const submit = () => {
    setLoading(true);
    onSubmit && onSubmit();
    close();
    setLoading(false);
  };
  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <div className={styles.body}>{message}</div>
          <div className={styles.footer}>
            <Button color={color} className={styles.cancel}>
              {cancelText}
            </Button>
            <Button {...submitProps} disabled={loading} onClick={submit}>
              {loading ? submitLoadingText : submitText}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmModal;
