import React from "react";

import styles from "./styles.module.scss";
import DropdownButton from "src/components/Dropdown/DropdownButton";
import { MenuItem } from "src/components/Dropdown/Menu";

const STATUSES = [undefined, "Invited", "Negotiating", "Connected"];
const StateFilter = ({ value, onChange }) => {
  return (
    <div>
      <DropdownButton
        alignEnd
        title={
          <div className={styles.header}>
            <div>Status:&nbsp;</div>
            <div className={styles.value}>{value || "All"}</div>
          </div>
        }
      >
        {STATUSES.map((status) => {
          const label = status || "All";
          return (
            <MenuItem key={label} onClick={() => onChange(status)}>
              {label}
            </MenuItem>
          );
        })}
      </DropdownButton>
    </div>
  );
};

export default StateFilter;
