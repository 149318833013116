import React, { useState } from "react";
import Modal from "src/components/Modal";
import CreateSchemaAndDefinitionForm from "./CreateSchemaAndDefinitionForm";
import CreateDefinitionForm from "./CreateDefinitionForm";
import styles from "./styles.module.scss";
import Button from "src/components/Inputs/Button";
import { useTranslation } from "react-i18next";
import { useSchemas } from "src/services/hooks/definitions";

const CreateCredentialDefinitionModal = ({ isOpen, setIsOpen, onSubmit }) => {
  const { t } = useTranslation();

  const [newSchema, setNewSchema] = useState(true);

  const { data: schemas } = useSchemas();

  let close = () => {
    setIsOpen(false);
  };

  const submitModal = async (w) => {
    onSubmit();
    close();
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>{t("createCredentialDefinition.modalTitle")}</h4>

          {schemas.length > 0 ? (
            <Button
              className={styles.createType}
              onClick={() => setNewSchema(!newSchema)}
            >
              {newSchema ? "Choose from existing schemas" : "Create new schema"}
            </Button>
          ) : null}

          {!newSchema && <CreateDefinitionForm onSubmit={submitModal} />}
          {newSchema && (
            <CreateSchemaAndDefinitionForm onSubmit={submitModal} />
          )}
        </div>
      )}
    </Modal>
  );
};

export default CreateCredentialDefinitionModal;
