import React from "react";
import classNames from "classnames";
import { useField } from "formik";
import Label from "src/components/Inputs/Label";
import Input from "src/components/Inputs/Input";
import Error from "src/components/Inputs/Error";

import styles from "./styles.module.scss";

const BaseFormInput = ({
  component: Component = Input,
  label,
  className,
  inputClassName,
  labelWidth = 200,
  horizontal,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div
      className={classNames(className, {
        [styles.horizontal]: horizontal,
      })}
    >
      {label && (
        <Label
          className={styles.label}
          style={horizontal ? { width: labelWidth } : {}}
        >
          {label}
        </Label>
      )}
      <Component
        {...field}
        {...props}
        className={inputClassName}
        style={horizontal ? { width: `calc(100% - ${labelWidth}px)` } : {}}
      />

      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </div>
  );
};

export default BaseFormInput;
