import React, { useEffect } from "react";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import Button from "src/components/Inputs/Button";

import styles from "./styles.module.scss";
import Steps from "./Steps";
import { MdClose } from "react-icons/md";

const InputAttributes = ({
  interactionFlow,
  touched,
  errors,
  arrayHelpers,
}) => {
  const { t } = useTranslation();

  const removeInputAttributes = async (index) => {
    arrayHelpers.remove(index);
  };

  useEffect(() => {
    if (
      interactionFlow.inputAttributes.length === 0 ||
      interactionFlow.inputAttributes[
        interactionFlow.inputAttributes.length - 1
      ] !== ""
    ) {
      arrayHelpers.push("");
    }
  }, [interactionFlow, arrayHelpers]);

  return (
    <div className={styles.container}>
      <div className={styles.inputAttributesTitle}>
        {t("interactionFlowForm.inputAttributes")}
      </div>
      {interactionFlow.inputAttributes.map((value, index) => (
        <div className={styles.header} key={index}>
          <FormInput
            name={`inputAttributes.${index}`}
            inputClassName={styles.inputAttributes}
          />
          {index < interactionFlow.inputAttributes.length - 1 && (
            <div
              className={styles.close}
              onClick={() => removeInputAttributes(index)}
            >
              <MdClose size={22} />
            </div>
          )}
        </div>
      ))}
      {touched && errors && (
        <div className={styles.error}>
          {Array.isArray(errors) ? "One of the items has errors" : errors}
        </div>
      )}
    </div>
  );
};

const InteractionFlowForm = ({ interactionFlow, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={
            interactionFlow || {
              name: "",
              info: "",
              public: false,
              inputAttributes: [],
              steps: [],
            }
          }
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            info: Yup.string(),
            public: Yup.bool(),
            inputAttributes: Yup.array(),
            steps: Yup.array().of(
              Yup.object().shape({
                action: Yup.string(),
                credentialMapping: Yup.array(),
                transport: Yup.string(),
                proofTemplateId: Yup.string(),
                timeoutMinutes: Yup.number(),
                isManualStep: Yup.bool(),
              })
            ),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput
                name="name"
                label={t("interactionFlowForm.name")}
                disabled={isSubmitting}
                className={styles.input}
              />
              <FormInput
                name="info"
                label={t("interactionFlowForm.info")}
                disabled={isSubmitting}
                className={styles.input}
              />

              {/*<FormInput*/}
              {/*  type="checkbox"*/}
              {/*  label={t("interactionFlowForm.public")}*/}
              {/*  disabled={isSubmitting}*/}
              {/*  className={styles.input}*/}
              {/*/>*/}

              <FieldArray name="inputAttributes">
                {({ form: { values: data }, ...arrayHelpers }) => (
                  <InputAttributes
                    interactionFlow={data}
                    disabled={isSubmitting}
                    arrayHelpers={arrayHelpers}
                    className={styles.input}
                  />
                )}
              </FieldArray>

              <p>{t("interactionFlowForm.steps")}</p>

              <FieldArray name="steps">
                {({ form, ...fieldArrayHelpers }) => {
                  const add = async () => {
                    fieldArrayHelpers.push({
                      action: "",
                      credentialMapping: [],
                      transport: "",
                      proofTemplateId: "",
                      timeoutMinutes: 0,
                      isManualStep: false,
                    });
                  };

                  const remove = async (index) => {
                    fieldArrayHelpers.remove(index);
                  };

                  return (
                    <Steps
                      name="steps"
                      items={form.values.steps}
                      errors={form.errors.steps}
                      touched={form.touched.steps}
                      onAdd={add}
                      onRemove={remove}
                    />
                  );
                }}
              </FieldArray>
              <Button
                type="submit"
                disabled={isSubmitting}
                className={styles.button}
              >
                {isSubmitting
                  ? t("interactionFlowForm.saving")
                  : t("interactionFlowForm.save")}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default InteractionFlowForm;
