import { useCallback, useEffect, useState } from "react";

export const EMPTY_STATE = {
  status: "idle",
  loading: false,
  data: undefined,
  error: undefined,
};

const useAsync = (asyncFunction, delayExecution) => {
  const [status, setStatus] = useState(EMPTY_STATE.status);
  const [loading, setLoading] = useState(EMPTY_STATE.loading);
  const [data, setData] = useState(EMPTY_STATE.data);
  const [error, setError] = useState(EMPTY_STATE.error);

  const execute = useCallback(
    async (...args) => {
      setLoading(true);
      setStatus("pending");
      setData();
      setError();
      try {
        const response = await asyncFunction(...args);
        setData(response.data);
        setError(response.error);
        setStatus(response.status);
        setLoading(false);
        return response;
      } catch (e) {
        setError(e);
        setStatus("error");
        setLoading(false);
      }
    },
    [asyncFunction]
  );

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (delayExecution) {
      return;
    }
    execute();
  }, [execute, delayExecution]);

  return {
    execute,
    loading,
    status,
    data,
    error,
    reset: () => {
      setData(EMPTY_STATE.data);
      setStatus(EMPTY_STATE.status);
      setError(EMPTY_STATE.error);
      setLoading(EMPTY_STATE.loading);
    },
  };
};

export default useAsync;
