import React, { useContext, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import FormInput from "src/components/Inputs/Formik/FormInput";
import Button from "src/components/Inputs/Button";
import { Link } from "react-router-dom";
import { AuthContext } from "src/providers/AuthProvider";

import styles from "./styles.module.scss";

const RegisterPage = () => {
  const [error, setError] = useState();
  const { t } = useTranslation();
  // const { isSignedIn, register } = useContext(AuthContext);
  const { register } = useContext(AuthContext);
  const [isSent, setIsSent] = React.useState(false);
  const handleRegister = async ({ email, password }) => {
    try {
      setError();
      await register({ email, password });
      setIsSent(true);
      // todo: redirect or display some message  setImmediate(() => history.push("/"));
    } catch (e) {
      setError(e.message || e.toString());
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={{
            email: "",
            password: "",
            repeatPassword: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(),
            password: Yup.string()
              .required()
              .min(6)
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
              ),
            repeatPassword: Yup.string().oneOf(
              [Yup.ref("password"), null],
              "Passwords must match"
            ),
          })}
          onSubmit={handleRegister}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <h6>{t("register.title")}</h6>
              <FormInput
                name="email"
                label={t("register.email")}
                className={styles.input}
                autoComplete="email"
              />
              <FormInput
                name="password"
                label={t("register.password")}
                type="password"
                className={styles.input}
                autoComplete="password"
              />
              <FormInput
                name="repeatPassword"
                label={t("register.repeatPassword")}
                type="password"
                className={styles.input}
              />

              {error && <div className={styles.error}>{error}</div>}
              <Button
                type="submit"
                disabled={isSubmitting}
                className={styles.button}
              >
                {t("register.register")}
              </Button>
              {isSent ? (
                <h4 className="success">Registration Successful!</h4>
              ) : (
                ""
              )}
              <div className={styles.footer}>
                <div>{t("register.haveAccount")}</div>
                <Link to="login">{t("register.login")}</Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RegisterPage;
