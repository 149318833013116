import React from "react";
import useAsync, { EMPTY_STATE } from "src/hooks/useAsync";
import { getNetworks } from "src/api/account";

export const AccountContext = React.createContext({
  networks: EMPTY_STATE,
});

export const AccountProvider = ({ children }) => {
  const networks = useAsync(getNetworks, true);

  return (
    <AccountContext.Provider
      value={{
        networks,
        reset: () => {
          networks.reset();
        },
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
