import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Checkbox = ({ className, onCheckedChanged, onChange, ...props }) => (
  <input
    className={classNames(styles.input, className)}
    onChange={(e) => {
      if (onChange) onChange(e);
      if (onCheckedChanged) onCheckedChanged(e.target.checked);
    }}
    {...props}
    type="checkbox"
  />
);

export default Checkbox;
