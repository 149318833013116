import React from "react";
import { MdArrowDropDown } from "react-icons/md";
import { useDropdownToggle } from "react-overlays";

import styles from "./styles.module.scss";

const Toggle = ({ id, children }) => {
  const [props, { toggle }] = useDropdownToggle();
  return (
    <div id={id} {...props} className={styles.container} onClick={toggle}>
      {children}
      <MdArrowDropDown size={20} />
    </div>
  );
};

export default Toggle;
