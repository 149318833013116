import React, { useState } from "react";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import InteractionFlowsTable from "./InteractionFlowsTable";
import InteractionFlowFormModal from "./InteractionFlowFormModal";
import Button from "src/components/Inputs/Button";
import ConfirmModal from "src/components/ConfirmModal";
import {
  useDeleteInteractionFlow,
  useInteractionFlows,
} from "src/services/hooks/definitions";

const InteractionFlowsPage = () => {
  const [confirmRemoveIsOpen, setConfirmRemoveIsOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [editedInteractionFlow, setEditedInteractionFlow] = useState();
  const [deletedInteractionFlow, setDeletedInteractionFlow] = useState();
  const { t } = useTranslation();

  const {
    data: interactionFlows,
    isLoading: loading,
    refetch: loadInteractionFlows,
  } = useInteractionFlows();

  const { mutateAsync: deleteInteractionFlow } = useDeleteInteractionFlow();

  const toggleModal = (w) => {
    setEditedInteractionFlow(w);
    setFormOpen(true);
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{t("interactionFlows.title")}</h4>
        <Button onClick={() => toggleModal()}>
          {t("interactionFlows.add")}
        </Button>
      </div>
      <div className={styles.table}>
        <InteractionFlowsTable
          loading={loading}
          items={interactionFlows}
          onEdit={toggleModal}
          onDelete={(w) => {
            setDeletedInteractionFlow(w);
            setConfirmRemoveIsOpen(true);
          }}
        />
      </div>
      {formOpen && (
        <InteractionFlowFormModal
          onSubmit={loadInteractionFlows}
          isOpen={formOpen}
          setIsOpen={setFormOpen}
          interactionFlow={editedInteractionFlow}
        />
      )}

      {confirmRemoveIsOpen && (
        <ConfirmModal
          isOpen={confirmRemoveIsOpen}
          setIsOpen={setConfirmRemoveIsOpen}
          submitProps={{ color: "warning" }}
          submitText="Delete"
          submitLoadingText="Deleting..."
          message="Are you sure you want to delete this interaction flow"
          onSubmit={async () => {
            await deleteInteractionFlow(deletedInteractionFlow.id);
            await loadInteractionFlows();
          }}
        />
      )}
    </div>
  );
};

export default InteractionFlowsPage;
