import React from "react";
import Table, {
  TableRow,
  TableRowCell,
  Tbody,
  Thead,
  TheadCell,
  TheadRow,
} from "src/components/Table";
import { MdCheck, MdClear } from "react-icons/md";

import { useTable } from "react-table";

import styles from "./styles.module.scss";
import Button from "src/components/Inputs/Button";

const CredentialDefinitionsTable = ({
  onItemClicked,
  onCreateOffer,
  loading,
  items,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Schema Name",
        accessor: "schema.name",
        Cell: ({ row }) => (
          <div
            className={styles.name}
            onClick={() => onItemClicked && onItemClicked(row.original)}
          >
            {row.original?.schema?.name || row.original?.schemaId || "not set"}
          </div>
        ),
      },
      {
        Header: "Definition ID",
        accessor: "id",
      },
      {
        Header: "Schema",
        accessor: "schemaId",
      },
      {
        Header: "Supports Revocation",
        accessor: "supportsRevocation",
        className: styles.supportsRevocation,
        headerClassName: styles.supportsRevocationHeader,
        Cell: ({ row }) => {
          const Icon = row?.original?.supportsRevocation ? MdCheck : MdClear;
          return <Icon size={22} />;
        },
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <Button
            variant="tableText"
            onClick={() => onCreateOffer(row.original)}
          >
            Issue
          </Button>
        ),
      },
    ],
    [onCreateOffer, onItemClicked]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: items || [] });

  return (
    <Table
      loading={loading}
      isEmpty={!items?.length}
      className={styles.table}
      {...getTableProps()}
    >
      <Thead>
        {headerGroups.map((headerGroup) => (
          <TheadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TheadCell
                {...column.getHeaderProps()}
                className={column.headerClassName}
              >
                {column.render("Header")}
              </TheadCell>
            ))}
          </TheadRow>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableRowCell
                    {...cell.getCellProps()}
                    className={cell.column?.className}
                  >
                    {cell.render("Cell")}
                  </TableRowCell>
                );
              })}
            </TableRow>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default CredentialDefinitionsTable;
