import React from "react";
import Input from "src/components/Inputs/Input";
import Checkbox from "src/components/Inputs/Checkbox";
import BaseFormInput from "src/components/Inputs/Formik/BaseFormInput";

const FormInput = ({ ...props }) => (
  <BaseFormInput
    component={props.type === "checkbox" ? Checkbox : Input}
    {...props}
  />
);
export default FormInput;
