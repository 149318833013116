import React, { useContext, useEffect, useState } from "react";

import CredentialsTable from "./CredentialsTable";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Button from "src/components/Inputs/Button";
import CollapsibleLayout from "src/components/Layout/CollapsibleLayout";
import DetailsSectionHeader from "src/components/Layout/DetailsSectionHeader";
import DetailsRowDisplay from "src/components/Layout/DetailsRowDisplay";
import { MdCheck, MdClear, MdRefresh } from "react-icons/md";
import { useCredentialsByCredentialDefinitionId } from "src/services/hooks/credentials";
import {
  useCredentialDefinitionById,
  useSchemas,
} from "src/services/hooks/definitions";
import { ModalContext } from "src/providers/ModalProvider";

const CredentialDefinitionPage = ({
  match: {
    params: { credentialDefinitionId },
  },
}) => {
  const { t } = useTranslation();
  const {
    data: credentials,
    isLoading: credentialsLoading,
    refetch: reloadCredentials,
  } = useCredentialsByCredentialDefinitionId(credentialDefinitionId);

  const {
    data: credentialDefinition,
    isLoading: loading,
  } = useCredentialDefinitionById(credentialDefinitionId);

  const { data: schemas } = useSchemas();
  const [schema, setSchema] = useState();

  const { toggleModal } = useContext(ModalContext);

  useEffect(() => {
    setSchema(schemas?.find(({ id }) => id === credentialDefinition?.schemaId));
  }, [credentialDefinition, schemas]);

  const toggleCreateOfferModal = () => {
    toggleModal("offer-credential", { credentialDefinitionId });
  };
  if (loading || !credentialDefinition) {
    return <div>Loading...</div>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{credentialDefinition.id}</h4>
      </div>
      <CollapsibleLayout>
        <div>
          <DetailsSectionHeader title={t("credentialDefinition.details")} />
          <DetailsRowDisplay
            label={t("credentialDefinition.definitionId")}
            value={credentialDefinition.id}
          />
          <DetailsRowDisplay
            label={t("credentialDefinition.schemaId")}
            value={credentialDefinition?.schemaId}
          />
          <DetailsRowDisplay
            label={t("credentialDefinition.schemaName")}
            value={schema?.name}
          />
          <DetailsRowDisplay
            label={t("credentialDefinition.supportsRevocation")}
            value={
              credentialDefinition.supportsRevocation ? (
                <MdCheck />
              ) : (
                <MdClear />
              )
            }
          />

          <DetailsSectionHeader title={t("credentialDefinition.attributes")} />

          {schema?.attributeNames?.map((n) => (
            <DetailsRowDisplay key={n} label={n} value="" />
          ))}
        </div>
        <div>
          <DetailsSectionHeader title={t("credentialDefinition.history")}>
            <div className={styles.actions}>
              <Button
                className={styles.actionButton}
                onClick={toggleCreateOfferModal}
              >
                {t("credentialDefinition.createOffer").toUpperCase()}
              </Button>
              <Button
                className={styles.refresh}
                variant="outlined"
                onClick={() => reloadCredentials()}
              >
                <MdRefresh size={22} />
              </Button>
            </div>
          </DetailsSectionHeader>
          <CredentialsTable items={credentials} loading={credentialsLoading} />
        </div>
      </CollapsibleLayout>
    </div>
  );
};

export default CredentialDefinitionPage;
