import React, { useContext } from "react";
import Modal from "src/components/Modal";

import WebhookForm from "../WebhookForm";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { WebhooksContext } from "src/providers/WebhooksProvider";

const WebhookFormModal = ({ webhook, isOpen, setIsOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    createWebhook: { execute: createWebhook },
    updateWebhook: { execute: updateWebhook },
  } = useContext(WebhooksContext);
  const close = () => setIsOpen(false);

  const submitForm = async (w) => {
    let status, data;
    if (webhook) {
      ({ status, data } = await updateWebhook(w));
    } else {
      ({ status, data } = await createWebhook(w));
    }
    if (status === "success") {
      onSubmit(data);
      close();
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>
            {webhook
              ? t("createWebhook.updateWebhook")
              : t("createWebhook.createWebhook")}
          </h4>
          <WebhookForm webhook={webhook} onSubmit={submitForm} />
        </div>
      )}
    </Modal>
  );
};

export default WebhookFormModal;
