import React, { useEffect, useState } from "react";
import Modal from "src/components/Modal";
import { ClipLoader } from "react-spinners";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import DisplayWithCopy from "src/components/DisplayWithCopy";
import QRCode from "src/components/QRCode";
import { useCreateConnection } from "src/services/hooks/connections";

const InviteConnectionModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [data, setData] = useState();

  const { mutate: createConnection } = useCreateConnection();

  useEffect(() => {
    if (isOpen) {
      createConnection(undefined, { onSuccess: setData });
    }
  }, [isOpen, createConnection]);

  let close = () => {
    setIsOpen(false);
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={close}>
      {isOpen && (
        <div className={styles.container}>
          <h4>{t("invite.title")}</h4>
          <div className={styles.qr}>
            {data?.uri ? (
              <QRCode content={data?.uri} />
            ) : (
              <div>
                <ClipLoader />
              </div>
            )}
          </div>
          <div className={styles.copy}>
            <label>Copy link</label>
            <div className={styles.copyValue}>
              <DisplayWithCopy value={data?.uri} />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default InviteConnectionModal;
